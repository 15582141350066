.btn {
  display: inline-block;
  transition: all 0.2s;
  border: none;

  &:focus {
    outline: none;
  }

  &:hover {
    transform: translateY(-0.2rem);
    box-shadow: 0 0.8rem 1rem rgba($black-1, 0.2);
    color: $red-1;
  }

  &:active {
    box-shadow: 0 0 0 rgba($black-1, 0.2);
    transform: translateY(0);
  }
  &-small {
    height: 3rem;
    width: 9rem;
    padding-top: 7px;
    background-color: $black-1;
    &:hover {
      color: $red-1;
    }
  }
  &-medium {
    height: 3.5rem;
    width: 15rem;
    margin: 0 12rem 2rem 9rem;
    @include respond(phone) {
      margin: 0;
    }
    &:hover {
      color: $white-1;
      background-color: $black-1;
      border: 1px solid $white-1;
    }

    &:active {
      color: $red-1;
      background-color: $black-1;
      border: 1px solid $red-1;
    }
  }
}
