.header {
  width: 100%;
  height: 80vh;
  background-image: url('../../resources/img/custom_resized_75ae378c-c2f8-4a55-b0da-6a14142fbab8.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20rem;
}

.section {
  width: 100%;
  &-info {
    position: relative;

    &__container {
      margin: 20rem auto;
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 40%;
    }

    &__anchor {
      position: absolute;
      display: block;
      top: -20%;
    }
  }

  &-services {
    position: relative;
    &__container {
      display: flex;
      width: 70%;
      margin: 10rem auto;
      &-holder {
        width: 50%;
        height: 50%;
      }
      &-img {
        width: 100%;
        height: 100%;
        background-size: cover;
      }
      &-word {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 4rem;
        width: 50%;
        vertical-align: top;
        @include respond(phone) {
          width: 100%;
        }
      }
      @include respond(phone) {
        flex-direction: column;
        align-items: center;
      }
    }
    &__anchor {
      position: absolute;
      display: block;
      top: -1%;
    }
  }

  &-contact {
    position: relative;

    &__container {
      display: flex;
      width: 100%;
      height: 100rem;
      // margin: 10rem auto;
    }
    &__info {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 4rem;
      width: 50%;
    }
    &__form-holder {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 2rem;
      width: 50%;
      // background-color: rgba($black-1, 0.9);
      background-color: $black-1;
    }

    &__entry {
      font-family: inherit;
      font-size: $font-size-1;
      text-align: start;
      margin: 0 auto 3rem 0;
    }

    &__top {
      // border-bottom: 1px solid $black-1;
      margin-bottom: 1.5rem;
    }

    &__bottom {
      font-weight: bold;
    }
    &__header {
      width: 60rem;
      @include respond(big-desktop) {
        width: 70rem;
      }
      @include respond(tab-land) {
        width: 50rem;
      }
      @include respond(tab-port) {
        width: 32rem;
      }
      @include respond(phone) {
        width: 15rem;
      }
    }

    &__header-1 {
      @include absCenter-1;
      border-bottom: 3px solid $black-1;
    }
    &__header-2 {
      color: $white-1;
      @include absCenter-2;
      border-bottom: 3px solid $white-1;
      @include respond(phone) {
        top: 18%;
      }
    }
    &__copy {
      position: absolute;
      left: 5%;
      top: 95%;
      color: $grey-1;
      @include respond(big-desktop) {
        left: 10%;
        top: 95%;
      }
      @include respond(tab-port) {
        left: 10%;
        top: 95%;
      }
      @include respond(phone) {
        left: 3%;
        top: 90%;
      }
    }
    &__anchor {
      position: absolute;
      display: block;
      top: -6%;
    }
  }
}
