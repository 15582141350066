.heading {
  &-primary {
    font-size: 8rem;
    color: $white-1;
    letter-spacing: 4rem;
    background-color: $black-1;
    width: 100rem;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.2s;
    &:hover {
      color: $red-1;
    }
    &__float {
      transition: all 0.5s;
      @include absCenter-3;
    }
    @include respond(big-desktop) {
      @include absCenter-4;
    }
    @include respond(tab-port) {
      width: 100%;
      letter-spacing: 3rem;
    }
    @include respond(phone) {
      width: 30rem;
      letter-spacing: 1rem;
    }
  }

  &-secondary {
    font-size: $font-size-6;
    width: 60rem;
    text-align: center;
    font-weight: normal;
    letter-spacing: 3rem;
    margin-bottom: 3rem;

    &__paragraph {
      font-size: $font-size-3;
      margin-bottom: 3rem;
    }

    &__span {
      font-size: $font-size-1;
      margin-bottom: 5rem;
      color: $grey-1;
      letter-spacing: 3rem;

      @include respond(tab-port) {
        letter-spacing: 1.5rem;
      }
      @include respond(phone) {
        letter-spacing: 0.5rem;
      }
    }

    @include respond(tab-port) {
      letter-spacing: 2rem;
      width: 45rem;
    }
    @include respond(phone) {
      letter-spacing: 1.5rem;
      width: 35rem;
    }
  }

  &-tertiary {
    font-weight: normal;
    font-size: $font-size-4;
    margin-bottom: 3rem;
    letter-spacing: 3rem;
    text-transform: uppercase;

    &__paragraph {
      font-size: $font-size-2;
      margin-bottom: 3rem;
    }

    @include respond(tab-port) {
      letter-spacing: 1rem;
      width: 33rem;
    }

    @include respond(phone) {
      letter-spacing: 0rem;
      width: 100%;
      margin: 0;
    }
  }
}

.color {
  color: $red-1;
}
