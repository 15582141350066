@mixin absCenter-1 {
  position: absolute;
  top: 20%;
  left: -50%;
  transform: translate(-50%, -50%);
}

@mixin absCenter-2 {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 20%);
}

@mixin absCenter-3 {
  position: absolute;
  top: 73%;
  left: 50%;
  transform: translate(-50%);
}

@mixin absCenter-4 {
  position: absolute;
  top: 76%;
  left: 50%;
  transform: translate(-50%);
}

// <media query manager>
// Order: Base + typography, general layout + grid, page layout,components.
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      // 600px
      @content;
    }
  }
  @if $breakpoint == tab-port {
    // 900px
    @media (max-width: 56.25em) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    // 1200px
    @media (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    // 1800px
    @media (min-width: 112.5em) {
      @content;
    }
  }
}
