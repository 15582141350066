.nav {
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 8rem;
  background-color: black;
  font-size: $font-size-0;
  font-weight: bold;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  padding-right: 1rem;
  padding-left: 1rem;

  &__logo {
    width: 8.5rem;
    left: 0;
    position: absolute;
    @include respond(phone) {
      width: 6rem;
    }

    &-img {
      background-size: cover;
      width: 102%;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    @include respond(phone) {
      flex-direction: column;
      align-items: start;
      justify-content: space-around;
      height: 80%;
      margin: 0 4rem;
      transform: translateX(60%);
      opacity: 0;
      transition: all 2s;
    }

    &-wrap {
      width: 100%;
      margin: 0 40rem;
      transition: all 0.7s ease;
      @include respond(tab-land) {
        margin: 0 22rem 0 10rem;
      }
      @include respond(phone) {
        transform: translateX(100%);
        position: absolute;
        top: 6rem;
        right: 0;
        display: flex;
        flex-direction: column;
        height: 90vh;
        width: 60%;
        background-color: $black-1;
        margin: 0;
        border-radius: 0 0 0 3px;
      }
    }

    &-anchor {
      position: relative;
      transition: all 0.2s;

      &:hover:after {
        width: 100%;
      }

      &:hover {
        color: $red-1;
      }
      @include respond(phone) {
      }
    }

    &-item {
      position: relative;
      @include respond(phone) {
        // display: flex;
      }
    }
  }

  &__location {
    position: absolute;
    padding-top: 2.5rem;
    right: 3rem;
    transition: all 0.2s;
    height: 8rem;
    text-align: center;

    &-anchor {
      color: $red-1;
      font-size: $font-size-1;
    }
    @include respond(phone) {
      display: none;
    }
  }

  &__dropdown {
    z-index: 101;
    display: none;
    background-color: $black-1;
    position: absolute;
    padding-top: 3rem;
    left: -1rem;
    width: 25rem;
    border-radius: 0 0 3px 3px;

    &-item {
      padding: 2rem;
      @include respond(phone) {
        height: 3rem;
        padding: 0;
      }
    }

    &-anchor {
      transition: all 0.2s;

      &:hover {
        color: $red-1;
      }
    }
    @include respond(phone) {
      padding: 0;
      position: relative;
      text-align: left;
      top: 1.5rem;
      left: 1rem;
      width: 20rem;
      background-color: transparent;
    }
  }
  @include respond(phone) {
    height: 6rem;
  }

  &-burger {
    margin: 1.7rem 1rem 1rem auto;
    display: none;
    width: 2.7rem;
    height: 5rem;
    cursor: pointer;
    position: relative;

    &__line {
      background-color: $white-1;
      height: 0.3rem;
      width: 100%;
      margin-bottom: 0.6rem;
      position: absolute;
      transition: all 0.2s ease;
    }

    &__line-1 {
      top: 0;
    }

    &__line-2 {
      top: 1rem;
    }

    &__line-3 {
      top: 2rem;
    }
    @include respond(phone) {
      display: block;
    }
  }
  @include respond(phone) {
    display: block;
  }
}

.services {
  &::before {
    content: "";
    position: absolute;
    border: 4px solid transparent;
    border-top: 4px solid white;
    margin-left: 2px;
    right: -1rem;
    bottom: 2px;
    display: inline-block;
    vertical-align: bottom;
  }
}

.slide {
  transform: translateX(0);
}

.toggle.nav-burger__line-1 {
  transform: rotate(-135deg);
  top: 1rem;
  display: none;
}

.toggle.nav-burger__line-2 {
  transform: rotate(45deg);
}

.toggle.nav-burger__line-3 {
  transform: rotate(-45deg);
  top: 1rem;
}

.fadeIn {
  transform: translateX(0);
  opacity: 1;
}

.block1 {
  display: block;
}

.block2 {
  display: block;
}
