// <colors>
$white-1: #fff;
$white-2: #eee;
$black-1: #000;
$grey-1: #9b9b9b;
$grey-2: #f3f3f3;
$grey-3: #999;
$orange-1: #e46c28;
$red-1: #e13934;

// </colors>
// <font-size>
$font-size-0: 1.2rem;
$font-size-1: 1.6rem;
$font-size-2: 1.9rem;
$font-size-3: 2rem;
$font-size-4: 2.3rem;
$font-size-5: 2.5rem;
$font-size-6: 3.5rem; // </font-size>
// <borders>
$border-radius-1: 0.5rem; // </borders>
