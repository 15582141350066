main {
  width: 100vw;
  height: 100vh;
  position: relative;
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  h1 {
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    font-family: "Avenir";
    letter-spacing: 0.5rem;
    height: 20rem;
    width: 90rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(white, 0.4);
    color: White;
  }
}
