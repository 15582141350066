*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: 'Avenir', sans-serif;
  box-sizing: border-box;
  font-weight: 400;
  line-height: 1.7;
}

html {
  font-size: 62.5%;

  @include respond(tab-land) {
    font-size: 56.25%; // 1rem = 9px, 9/16 = 56.25%
  }
  @include respond(tab-port) {
    font-size: 50%; // 1rem = 8px, 8/16 = 50%
  }
  @include respond(phone) {
    font-size: 37.5%; // 1rem = 6px, 6/16 = 37.5%
  }
  @include respond(big-desktop) {
    font-size: 75%; // 1rem = 12px, 12/16 = 75%
  }
}

a {
  text-decoration: none;
  color: $white-1;
}

li {
  list-style-type: none;
}

// PORTED STYLES







:root {
  --ffsd: 0px;
  --1vw: calc((100vw - var(--sbw, 0px)) / 100);
  --1vh: var(--inner1Vh, 1vh);
}

@media (prefers-reduced-motion: reduce) {
  .animated {
      animation: none !important;
  }
}


li {
  text-align: unset;
}



img {
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}


#EHJe4a9TjZnF5oTb {
    height: 90vh;
}
// #qxi1dJYpuEgQ3GAP{
//     font-size: 9px;
// }

@media (max-width: 375px) {
  #Gcip3IHrXqoSSerB {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #R4bFHk3K9MhsUuXQ {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #uB9gdUBrmjPAQVE6 {
      grid-area: 4 / 3 / 5 / 4;
      position: relative;
  }

  #xYbBrx6mafzrIqCT {
      font-size: calc(2.62903367em - var(--ffsd));
  }

  #AnOXjoZUpH4TNBbq {
      --first-font-size: 2.62903367em;
      --last-font-size: var(--first-font-size);
      transform: translateX(calc(var(--first-font-size) * 0.02));
      margin-top: calc(var(--first-font-size) * 0.04);
      margin-bottom: calc(var(--last-font-size) * 0.04);
  }

  #FX2sUpNG3cn48QTY {
      min-width: 28.63198639rem;
  }

  #BL7fbA4BBx5IpnqI {
      grid-area: 6 / 2 / 7 / 5;
      position: relative;
  }

  #okdJh55tKLEwvioS {
      grid-area: 2 / 6 / 8 / 7;
      position: relative;
  }

  #Hj2vCWH2yaYdooEL {
      grid-area: 2 / 2 / 9 / 8;
      position: relative;
  }

  #hNM83Z741TiR9kHy {
      grid-area: 3 / 3 / 4 / 6;
      position: relative;
  }

  #gj4YJ4yvRxKWtsAH {
      grid-area: 5 / 5 / 6 / 7;
      position: relative;
  }

  #gdFbF9eUNpIBUzs1 {
      grid-area: 7 / 4 / 8 / 9;
      position: relative;
  }

  #aa2J8LgSNASnS01p {
      grid-template-columns: 0 1.62960034rem 3.10884135rem 8.02863446rem 18.54622249rem 1.62803783rem 0.00156251rem 0;
      grid-template-rows: 0 minmax(2.57868613rem, max-content) minmax(49.84863032rem, max-content) minmax(0.95167889rem, max-content) minmax(0.29282577rem, max-content) minmax(1.45157349rem, max-content) minmax(0.44029501rem, max-content) minmax(0.65885798rem, max-content);
  }

  #mDREwzGjCETykcMJ {
      grid-area: 3 / 8 / 9 / 9;
      position: relative;
  }

  #yH1jqxz2dwWbkSRs {
      grid-template-columns: 2.8077745rem 5.59080714rem 17.31703877rem 5.59080714rem 3.68386429rem 32.06680918rem 0 32.94289898rem;
      grid-template-rows: 0 0 minmax(2.90260036rem, max-content) minmax(21.72073144rem, max-content) minmax(2.74923952rem, max-content) minmax(14.85161113rem, max-content) minmax(13.99836513rem, max-content) 0;
  }

  #EHJe4a9TjZnF5oTb {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #ZvAIGWL199mtdQty {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hd9DCGsDJbnCUTMH {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #p2klDFBhgs2IIzu0 {
      font-size: calc(4.00191801em - var(--ffsd));
  }

  #L6b9uLo7BkyIwbjg {
      font-size: calc(2.73303807em - var(--ffsd));
  }

  #AB5tuhlSHL4A61dS {
      --first-font-size: 4.00191801em;
      --last-font-size: 2.73303807em;
      margin-top: calc(var(--first-font-size) * -0.18);
      margin-bottom: calc(var(--last-font-size) * -0.18);
  }

  #NDPGsVcVomdqV1BM {
      min-width: 88.88882382rem;
  }

  #r90enHsYajhqod6n {
      grid-area: 2 / 2 / 4 / 5;
      position: relative;
  }

  #EBbqjM05jht5l4qT {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #Zh5lAIkgkw3mQD23 {
      font-size: calc(2.45061493em - var(--ffsd));
  }

  #KA1ruxmY3fDQ0CHz {
      --first-font-size: 2.45061493em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #ULKpIAXmD5Yo6ZUm {
      min-width: 21.98833115rem;
  }

  #AkuMzJ6ee2hQPfpP {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #oRCzniDlPNnYzH32 {
      grid-template-columns: 0 0.93583152rem 21.85499782rem 0;
      grid-template-rows: 0 minmax(1.69560038rem, max-content) minmax(2.87737335rem, max-content) minmax(1.83694702rem, max-content);
  }

  #EUGlla4aWTH7F2vY {
      grid-area: 3 / 3 / 5 / 4;
      position: relative;
  }

  #M26r7EuNKRfQjP1f {
      grid-template-columns: 5.62225476rem 32.98233057rem 22.79082934rem 32.98233057rem 5.62225476rem;
      grid-template-rows: minmax(11.03806406rem, max-content) minmax(32.45081909rem, max-content) minmax(1.69560038rem, max-content) minmax(4.71432037rem, max-content) minmax(6.32374368rem, max-content);
  }

  #qxi1dJYpuEgQ3GAP {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #FFamOyY9L43hFdCg {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hgmCugqHt6bM7JSf {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #sbid0hVA5U1i60dK {
      font-size: calc(3.12344802em - var(--ffsd));
  }

  #td6EGz77roQlxnv3 {
      --first-font-size: 3.12344802em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #WemUxubcgv2ggcRw {
      min-width: 50.49829684rem;
  }

  #NwkRNePE1Q9SWrKB {
      grid-area: 2 / 2 / 3 / 6;
      position: relative;
  }

  #Ty23ULE0tB2Nvpea {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #hkg6wjagLVyf04N9 {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #vqV4OOlDqnBupciK {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #ic2Hde6op0tE3pOV {
      --first-font-size: 2.24499268em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.24);
      margin-bottom: calc(var(--last-font-size) * -0.24);
  }

  #nYolEmHEUkq6c9dk {
      min-width: 53.80223683rem;
  }

  #ZRGfJC8CVYOn4ICt {
      grid-area: 4 / 3 / 5 / 7;
      position: relative;
  }

  #Pg1H9KHcUrYWirzx {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #u97ggHtTl5CjCFr2 {
      font-size: calc(2.40915081em - var(--ffsd));
  }

  #NhjFutAzLTBEK3N4 {
      --first-font-size: 2.40915081em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #MUvZvHoZnbpQy2Fg {
      min-width: 21.61852007rem;
  }

  #KxUBSgbSii92WswK {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #ijhRrpO0ZVZxzsH2 {
      grid-template-columns: 0 1.16722813rem 21.48518673rem 1.16722813rem;
      grid-template-rows: 0 minmax(1.86581736rem, max-content) minmax(2.88566618rem, max-content) minmax(1.94779105rem, max-content);
  }

  #JE09gLAD0lOkMTkB {
      grid-area: 6 / 4 / 7 / 5;
      position: relative;
  }

  #OvvMkFI95PY5lI31 {
      grid-template-columns: 5.62225476rem 0 0.6803525rem 23.81964299rem 25.86496801rem 3.30393999rem 40.70884175rem;
      grid-template-rows: minmax(12.70186915rem, max-content) minmax(10.06832064rem, max-content) minmax(2.20763838rem, max-content) minmax(10.12298917rem, max-content) minmax(5.21125733rem, max-content) minmax(6.69927459rem, max-content) minmax(9.21119832rem, max-content);
  }

  #Cy1PaoWfviQxRs5a {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #RmijFNceVqO7Fs3J {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #y1Vye7h8aQaaCSRZ {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #EsHH1pIVIoFxMfcb {
      font-size: calc(2.84680844em - var(--ffsd));
  }

  #jzrAYhXhr34yZVBt {
      --first-font-size: 2.84680844em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #mg6YZjNYcDGsDEM9 {
      min-width: 50.65172281rem;
  }

  #QRqrShqvd6dgCaht {
      grid-area: 2 / 2 / 3 / 4;
      position: relative;
  }

  #JIuf7v0lKOXoVVAx {
      font-size: calc(1.89786755em - var(--ffsd));
  }

  #RIKncFyr9pIUSUie {
      --first-font-size: 1.89786755em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #u9MNcVEjAOZ3rGlU {
      min-width: 50.65172281rem;
  }

  #jXpmqkj2JmUatY0n {
      grid-area: 4 / 3 / 5 / 5;
      position: relative;
  }

  #Iikie6NHQzNDHWuB {
      grid-template-columns: 0 0 50.51838948rem 0;
      grid-template-rows: 0 minmax(3.35923395rem, max-content) minmax(1.10534723rem, max-content) minmax(4.8870221rem, max-content);
  }

  #dsGaowxU9EVzl1tT {
      grid-area: 3 / 2 / 4 / 18;
      position: relative;
  }

  #MIRJ2qV9jGAzI4nY {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #HbBuYw0oCiveIm75 {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #gNgIpczgkfSqPrT7 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #WscXJmmrtcwvsJRP {
      min-width: 18.82542988rem;
  }

  #c13F6904MVKNpyVT {
      grid-area: 5 / 3 / 8 / 12;
      position: relative;
  }

  #U85i265wNQbw8T6A {
      grid-area: 6 / 14 / 7 / 19;
      position: relative;
  }

  #fGXtQn2TxF0u8sQK {
      grid-area: 9 / 4 / 10 / 23;
      position: relative;
  }

  #iBI7gsSYLx6PsCWJ {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #TQqu6u0aLQxKLqIb {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #LjHLSRvoqG4r58Lk {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #QhGfiNC4tKlmqZXA {
      min-width: 13.233283rem;
  }

  #qtZ1Kp35L1YFOMrC {
      grid-area: 11 / 5 / 14 / 11;
      position: relative;
  }

  #onNVpINpFa5zgEgR {
      grid-area: 12 / 15 / 13 / 20;
      position: relative;
  }

  #WOFSN6jl6Ycg0BXK {
      grid-area: 15 / 6 / 16 / 24;
      position: relative;
  }

  #WwdPYj5hi5OJEaQS {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #CMjC5N8UaaUdalAx {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #m4k18ITxxsL9QFGM {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #L89XGpgiQRagWZxL {
      min-width: 29.66374676rem;
  }

  #sfuRSbOq2STMCRnA {
      grid-area: 17 / 7 / 20 / 13;
      position: relative;
  }

  #uWlILl6qsIML3Abq {
      grid-area: 18 / 16 / 19 / 21;
      position: relative;
  }

  #GxLZcKsgC2Hilfjd {
      grid-area: 21 / 8 / 22 / 25;
      position: relative;
  }

  #aQWCknqjztYHpTod {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #XXC7EZjp69bNCAIa {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #vpnVdpcR5ZSWo8T3 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #mUHVS577UYfY5gke {
      min-width: 13.01774378rem;
  }

  #gRtoAZXOZ73ARV3Y {
      grid-area: 23 / 9 / 26 / 10;
      position: relative;
  }

  #SvMKEAw6mQYt3EGi {
      grid-area: 24 / 17 / 25 / 22;
      position: relative;
  }

  #VptxBkH6YTytsvBQ {
      grid-area: 2 / 26 / 27 / 27;
      position: relative;
  }

  #rF78NrtcKVQUrqZt {
      grid-template-columns: 3.9691309rem 1.65312386rem 0 0 0 0 0 0 12.88441045rem 0.21553922rem 5.59214688rem 10.83831689rem 17.56701623rem 0 0 0 1.76783596rem 0 0 0 0 1.54449348rem 0 0 3.56702802rem 34.77870336rem 5.62225476rem;
      grid-template-rows: minmax(5.62225476rem, max-content) minmax(6.20255199rem, max-content) minmax(9.35160328rem, max-content) minmax(5.0984772rem, max-content) minmax(0.68549779rem, max-content) minmax(1.76783596rem, max-content) minmax(1.41196786rem, max-content) minmax(1.24450952rem, max-content) minmax(0.26842511rem, max-content) minmax(1.31771596rem, max-content) minmax(0.93210419rem, max-content) minmax(1.76783596rem, max-content) minmax(1.16536146rem, max-content) minmax(1.22388171rem, max-content) minmax(0.26842511rem, max-content) minmax(1.46412884rem, max-content) minmax(1.06698088rem, max-content) minmax(1.76783596rem, max-content) minmax(1.03048477rem, max-content) minmax(1.31771596rem, max-content) minmax(0.26842511rem, max-content) minmax(1.49097184rem, max-content) minmax(0.77842787rem, max-content) minmax(1.76783596rem, max-content) minmax(1.31903778rem, max-content) 0 minmax(5.62225476rem, max-content);
  }

  #IGQgWz9buyfmxwOX {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #RBLku5bRpfbZzoL5 {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #moYcaMc9ZCjaQ5Kk {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #l77XCv5ipqp7OSGG {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #tldXqR85ukzB2Xpc {
      font-size: calc(2.06930454em - var(--ffsd));
  }

  #v8dAWh4by7M5pxbI {
      font-size: calc(2.06930454em - var(--ffsd));
  }

  #c7XZiZxPxw03OdfC {
      --first-font-size: 2.06930454em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #mAkg4ABrRdFOVI2J {
      min-width: 19.08019212rem;
  }

  #Pwn7cfpx3FxXo8Mo {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #Z1C31VCWpC0VLV2V {
      grid-template-columns: 0 0.80527086rem 18.94685878rem 0.87847731rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(23.97657321rem, max-content) minmax(4.86802466rem, max-content);
  }

  #u8PbdJDPhGxEhVYX {
      grid-area: 3 / 2 / 9 / 3;
      position: relative;
  }

  #OjAxnq7DlESlsM2B {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #MaNyMNNQPmAYqeNO {
      font-size: calc(2.07392489em - var(--ffsd));
  }

  #ksBwM714QSw4PcGn {
      font-size: calc(2.07392489em - var(--ffsd));
  }

  #KI6UGKNb7ekfusHH {
      --first-font-size: 2.07392489em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #pwOsh6myIKro0Zxz {
      min-width: 18.21682477rem;
  }

  #oVRfPyZBKj86KKEy {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #a2VFO8DotspSemB7 {
      grid-template-columns: 0 1.10293386rem 18.08349143rem 0.94511022rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(23.67254475rem, max-content) minmax(5.17205312rem, max-content);
  }

  #QhxDFEMAfBE4jrLj {
      grid-area: 4 / 4 / 10 / 5;
      position: relative;
  }

  #i5SQlFa08mWt8OU7 {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #nHe50ftLL79P3xgb {
      font-size: calc(2.1473851em - var(--ffsd));
  }

  #BAHZRzIaByTsgLKw {
      font-size: calc(0.78090044em - var(--ffsd));
  }

  #YCRd8V2uExBvjkWO {
      --first-font-size: 2.1473851em;
      --last-font-size: 0.78090044em;
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #HDEyyYz0SekFT46Y {
      min-width: 11.88977895rem;
  }

  #CavGOY9hjCOkcDp6 {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #v9Bn2VIBikYtngpe {
      grid-template-columns: 0 0 11.75644562rem 0;
      grid-template-rows: 0 minmax(4.33626135rem, max-content) minmax(3.74182372rem, max-content) minmax(6.87446899rem, max-content);
  }

  #oK5ft2mais3eEpdb {
      grid-area: 2 / 11 / 5 / 12;
      position: relative;
  }

  #kI5ApGItmf85yhck {
      grid-area: 6 / 6 / 11 / 9;
      position: relative;
  }

  #cnFEflgiysujhtx6 {
      font-size: calc(2.23152334em - var(--ffsd));
  }

  #RiegmrffEAK6Ib0v {
      font-size: calc(1.78990963em - var(--ffsd));
  }

  #J4wEpTeqRP5K1HcN {
      font-size: calc(1.78990963em - var(--ffsd));
  }

  #r3V5CUIdBRTJnFOm {
      --first-font-size: 2.23152334em;
      --last-font-size: 1.78990963em;
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #mPGRDzyWfG82Z5P8 {
      min-width: 18.18300044rem;
  }

  #UXbjzZC3YybOVETT {
      grid-area: 8 / 7 / 13 / 8;
      position: relative;
  }

  #Nd6DmbZ6aBQIRg0a {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #rgkJVqu4suGcTb7S {
      font-size: calc(2.23152269em - var(--ffsd));
  }

  #evcD5UXFTfvykY49 {
      font-size: calc(1.8740776em - var(--ffsd));
  }

  #YsqZH9U9kIXmnuMA {
      --first-font-size: 2.23152269em;
      --last-font-size: 1.8740776em;
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #YlGLAzV42ZwrciJk {
      min-width: 18.18300044rem;
  }

  #Wpq2384czQxH5ar0 {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #wKILIpJB3Xh5vn2P {
      grid-template-columns: 0 0.8228348rem 18.0496671rem 0.8228348rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(24.93320827rem, max-content) minmax(3.9113896rem, max-content);
  }

  #jPrUqChMZEzXArN7 {
      grid-area: 7 / 10 / 12 / 13;
      position: relative;
  }

  #Sa7Jx5tZzWs5J5nY {
      grid-template-columns: 5.79217985rem 20.63060695rem 2.34260615rem 20.13153551rem 2.59398003rem 1.19238944rem 18.0496671rem 1.35290664rem 1.97657394rem 7.93889108rem 11.75644562rem 0 6.2422177rem;
      grid-template-rows: 0 minmax(14.95255405rem, max-content) 0 0 0 0 minmax(6.80314091rem, max-content) minmax(28.84459787rem, max-content) 0 0 0 minmax(0.42608356rem, max-content) minmax(5.1961712rem, max-content);
  }

  #zwKgah8dqbXVOUpH {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #SggXgrATxLg27eBg {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #T3PpJIYdrbgl5nVF {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #Cmsk5UCmTRIxuVBf {
      grid-area: 3 / 4 / 5 / 7;
      position: relative;
  }

  #X8l0aTR5IlPmwv6M {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hOE8WAqz5b9ndbqd {
      grid-template-columns: 0 44.39589498rem;
      grid-template-rows: 0 minmax(33.97268383rem, max-content);
  }

  #EONmJlhFuBfu0xiM {
      grid-area: 4 / 2 / 6 / 6;
      position: relative;
  }

  #G5XcDjTvJfhcL1KU {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #Wt9TTYELXBum9yxE {
      font-size: calc(2.45912884em - var(--ffsd));
  }

  #sJEemP9xMe2ftdkJ {
      --first-font-size: 2.45912884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #q8ggHWJBcXqflX2C {
      min-width: 22.06421025rem;
  }

  #qSHJbRetCVKoRsdO {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #Rf1saeE2U1lQU1vt {
      grid-template-columns: 0 0 21.93087692rem 0.01242755rem;
      grid-template-rows: 0 minmax(1.5711768rem, max-content) minmax(3.02208346rem, max-content) minmax(1.57829413rem, max-content);
  }

  #KtETJnG6VhxbQeN3 {
      grid-area: 7 / 3 / 8 / 5;
      position: relative;
  }

  #W12Mp8BOWiruKw9c {
      font-size: calc(2.92825769em - var(--ffsd));
  }

  #xNnCswL01UvjYUMd {
      --first-font-size: 2.92825769em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #c2ZiFqm2RsHSfKuj {
      min-width: 25.02293575rem;
  }

  #ykzECYCWLJWIMnLZ {
      grid-area: 2 / 2 / 3 / 4;
      position: relative;
  }

  #RsdNPazz5xf8WxLz {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #V8kGV1eLT5Tr5VZq {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #xDjh9qFYQ29yPKXW {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #I4jckjq6QSn0pIBp {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #kzdHaTA9PJVl4OaB {
      --first-font-size: 1.95215959em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #XexK9M4YS6jTwRSB {
      min-width: 25.02293575rem;
  }

  #OK5e9K9ewpI2JMKx {
      grid-area: 4 / 3 / 5 / 5;
      position: relative;
  }

  #w1wHkC1Yu5MxO1LO {
      grid-template-columns: 0 0 24.88960242rem 0;
      grid-template-rows: 0 minmax(3.45534407rem, max-content) minmax(2.55526197rem, max-content) minmax(45.6564202rem, max-content);
  }

  #OK1vTNfjGM1ov4vV {
      grid-area: 2 / 8 / 9 / 9;
      position: relative;
  }

  #wqYjr9PTCOkGmL0C {
      grid-template-columns: 5.60410502rem 19.0615714rem 3.90304349rem 18.04026098rem 3.3910191rem 15.21492213rem 2.70986191rem 24.88960242rem 7.18561354rem;
      grid-template-rows: minmax(10.09455456rem, max-content) minmax(1.43804374rem, max-content) minmax(4.48094564rem, max-content) minmax(18.76945765rem, max-content) minmax(15.20322618rem, max-content) minmax(4.81737686rem, max-content) minmax(6.17155438rem, max-content) minmax(0.7864218rem, max-content) minmax(10.09455456rem, max-content);
  }

  #T2NMkGKZOUkwAcmX {
      min-height: calc(17.56051855 * var(--1vh, 1vh));
  }

  #vAFmLVjpViOO8pEh {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #NdSP5GhekaOasljF {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #N6VpBGKRcBI8WMxl {
      font-size: calc(2.92825769em - var(--ffsd));
  }

  #ULaD2Y01Sk5mMOak {
      --first-font-size: 2.92825769em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #nRfnhX0YzOaQ0Xdw {
      min-width: 26.39791899rem;
  }

  #eRPPNza9h5HcdgF7 {
      grid-area: 2 / 2 / 3 / 9;
      position: relative;
  }

  #oHXHqNq8CxsueFnV {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #bylecg2blPm2OwBL {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #koN7beDlYDRe7jfg {
      min-width: 26.39791899rem;
  }

  #owTAQ0z6seFDL7zt {
      grid-area: 4 / 3 / 5 / 10;
      position: relative;
  }

  #n6ALYRGoNIPBU9e2 {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #E5A0QPuBdhE3VROO {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #vUxUbys62KfUqI6i {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #ZV7uUR4i3lj84q73 {
      min-width: 26.39791899rem;
  }

  #MRdQu4FBvWGPGryX {
      grid-area: 6 / 4 / 7 / 11;
      position: relative;
  }

  #aTIpy788F0YNw6gv {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #FBw4YWZt1Y5OIuqw {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #sXAaBpS0zx4H2vOJ {
      min-width: 26.39791899rem;
  }

  #BGFMS2RR1T9II4YV {
      grid-area: 8 / 5 / 9 / 12;
      position: relative;
  }

  #BYPYCHDU7OlSjV2c {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #OMWWFAeGDtRPV7Sb {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #mO4wzJEPpkxOftS4 {
      min-width: 26.39791899rem;
  }

  #bxYt3OgDKC4xflmY {
      grid-area: 10 / 6 / 11 / 13;
      position: relative;
  }

  #XkHSwDE7oqwMyWL7 {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #pFHmlClCkB6kdaez {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #pD0kzGq1kLx54a2S {
      min-width: 26.39791899rem;
  }

  #hpeyZ92ab31eREpU {
      grid-area: 12 / 7 / 13 / 14;
      position: relative;
  }

  #iLAnHYrLq0AZ1fbe {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #fVzlRlIKSIjHpp2J {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #jiNcs7LojO8ChMNY {
      min-width: 26.39791899rem;
  }

  #TDcUqR484nocyt77 {
      grid-area: 14 / 8 / 15 / 15;
      position: relative;
  }

  #ejASdF8seWdXb62i {
      grid-template-columns: 0 0 0 0 0 0 0 26.26458566rem 0 0 0 0 0 0;
      grid-template-rows: 0 minmax(3.45534407rem, max-content) minmax(3.18272207rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(3.80673499rem, max-content) minmax(1.98516502rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(1.75695461rem, max-content) minmax(1.98516502rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(1.75695461rem, max-content);
  }

  #WsXKJi0tUQy6M0Yj {
      grid-area: 3 / 2 / 4 / 5;
      position: relative;
  }

  #o103wLUjzjdJ0vbj {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #bLn45zJKclaSXxXB {
      font-size: calc(2.14738653em - var(--ffsd));
  }

  #gWwroNFESTLBcPfT {
      --first-font-size: 2.14738653em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #YWW3MOpmOgfb841P {
      min-width: 19.28406381rem;
  }

  #JCjXP2hfxHABIryt {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #cptnjYzeKMbd7tD2 {
      grid-template-columns: 0 0 19.15073048rem 0;
      grid-template-rows: 0 minmax(1.37047484rem, max-content) minmax(2.64519327rem, max-content) minmax(1.37047484rem, max-content);
  }

  #dNV7QOHJmBMIR8do {
      grid-area: 5 / 3 / 8 / 4;
      position: relative;
  }

  #ODW2Zr4KWDqq5gBJ {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #K0gdNuRVqJ4C0sMP {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #J3wv3cJg8jTi4Gn6 {
      grid-template-columns: 0 18.61661692rem 23.99889497rem 18.61661692rem;
      grid-template-rows: 0 0 minmax(30.47749495rem, max-content) minmax(3.99765807rem, max-content);
  }

  #AW5WKBsna8IRStre {
      grid-area: 2 / 6 / 6 / 9;
      position: relative;
  }

  #ExVlBTD6zGtQxqgL {
      grid-area: 2 / 2 / 7 / 7;
      position: relative;
  }

  #UMhTMNGScmWqTQH2 {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #FIC9eZslsweKSBg0 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #uWXcr0A1k6OazN9g {
      min-width: 23.99945372rem;
  }

  #bAa0QtZVlTHParfB {
      grid-area: 4 / 3 / 5 / 4;
      position: relative;
  }

  #MU7RIMCyCaEqHZOq {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #Cwnw3RXYBtarBT3W {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #DmTeAPJIN9S3eeqO {
      grid-template-columns: 0 0.8965513rem 1.59410699rem 0.84795565rem;
      grid-template-rows: 0 minmax(0.87225348rem, max-content) minmax(1.59410699rem, max-content) minmax(0.87225348rem, max-content);
  }

  #zbHp22QpXxxTF8oc {
      grid-template-columns: 0 4.47419318rem 23.86612039rem 2.19283912rem 3.33861395rem 5.64337528rem;
      grid-template-rows: 0 minmax(0.9399522rem, max-content) minmax(0.61513347rem, max-content) minmax(2.108347rem, max-content) minmax(0.61513347rem, max-content) minmax(1.39410641rem, max-content);
  }

  #ysr65jjvWIT1gbxc {
      grid-area: 7 / 7 / 9 / 8;
      position: relative;
  }

  #eIdYn4hrBVqteSux {
      grid-template-columns: 5.62225476rem 1.65312808rem 19.15073048rem 5.4607271rem 2.37352442rem 10.85849344rem 39.51514192rem 10.85849344rem 4.50750636rem;
      grid-template-rows: minmax(12.87252632rem, max-content) minmax(1.97462047rem, max-content) minmax(26.528254rem, max-content) minmax(3.83874909rem, max-content) minmax(2.13352945rem, max-content) minmax(1.29364199rem, max-content) minmax(1.95897151rem, max-content) minmax(3.71370105rem, max-content) minmax(1.90855371rem, max-content);
  }

  #c85MiQ8j8pyAFfoz {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }
}

@media (min-width: 375.05px) and (max-width: 480px) {
  #Gcip3IHrXqoSSerB {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #R4bFHk3K9MhsUuXQ {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #uB9gdUBrmjPAQVE6 {
      grid-area: 4 / 3 / 5 / 4;
      position: relative;
  }

  #xYbBrx6mafzrIqCT {
      font-size: calc(2.62903367em - var(--ffsd));
  }

  #AnOXjoZUpH4TNBbq {
      --first-font-size: 2.62903367em;
      --last-font-size: var(--first-font-size);
      transform: translateX(calc(var(--first-font-size) * 0.02));
      margin-top: calc(var(--first-font-size) * 0.04);
      margin-bottom: calc(var(--last-font-size) * 0.04);
  }

  #FX2sUpNG3cn48QTY {
      min-width: 28.60281972rem;
  }

  #BL7fbA4BBx5IpnqI {
      grid-area: 6 / 2 / 7 / 5;
      position: relative;
  }

  #okdJh55tKLEwvioS {
      grid-area: 2 / 6 / 8 / 7;
      position: relative;
  }

  #Hj2vCWH2yaYdooEL {
      grid-area: 2 / 2 / 9 / 8;
      position: relative;
  }

  #hNM83Z741TiR9kHy {
      grid-area: 3 / 3 / 4 / 6;
      position: relative;
  }

  #gj4YJ4yvRxKWtsAH {
      grid-area: 5 / 5 / 6 / 7;
      position: relative;
  }

  #gdFbF9eUNpIBUzs1 {
      grid-area: 7 / 4 / 8 / 9;
      position: relative;
  }

  #aa2J8LgSNASnS01p {
      grid-template-columns: 0 1.62960034rem 3.10884135rem 8.02863446rem 18.54622249rem 1.62803783rem 0.00156251rem 0;
      grid-template-rows: 0 minmax(2.57868613rem, max-content) minmax(49.84863032rem, max-content) minmax(0.95167889rem, max-content) minmax(0.29282577rem, max-content) minmax(1.45157349rem, max-content) minmax(0.44029501rem, max-content) minmax(0.65885798rem, max-content);
  }

  #mDREwzGjCETykcMJ {
      grid-area: 3 / 8 / 9 / 9;
      position: relative;
  }

  #yH1jqxz2dwWbkSRs {
      grid-template-columns: 2.8077745rem 5.59080714rem 17.31703877rem 5.59080714rem 3.68386429rem 32.06680918rem 0 32.94289898rem;
      grid-template-rows: 0 0 minmax(2.90260036rem, max-content) minmax(21.72073144rem, max-content) minmax(2.74923952rem, max-content) minmax(14.85161113rem, max-content) minmax(13.99836513rem, max-content) 0;
  }

  #EHJe4a9TjZnF5oTb {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #ZvAIGWL199mtdQty {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hd9DCGsDJbnCUTMH {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #p2klDFBhgs2IIzu0 {
      font-size: calc(4.00191801em - var(--ffsd));
  }

  #L6b9uLo7BkyIwbjg {
      font-size: calc(2.73303807em - var(--ffsd));
  }

  #AB5tuhlSHL4A61dS {
      --first-font-size: 4.00191801em;
      --last-font-size: 2.73303807em;
      margin-top: calc(var(--first-font-size) * -0.18);
      margin-bottom: calc(var(--last-font-size) * -0.18);
  }

  #NDPGsVcVomdqV1BM {
      min-width: 88.85965715rem;
  }

  #r90enHsYajhqod6n {
      grid-area: 2 / 2 / 4 / 5;
      position: relative;
  }

  #EBbqjM05jht5l4qT {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #Zh5lAIkgkw3mQD23 {
      font-size: calc(2.45061493em - var(--ffsd));
  }

  #KA1ruxmY3fDQ0CHz {
      --first-font-size: 2.45061493em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #ULKpIAXmD5Yo6ZUm {
      min-width: 21.95916448rem;
  }

  #AkuMzJ6ee2hQPfpP {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #oRCzniDlPNnYzH32 {
      grid-template-columns: 0 0.93583152rem 21.85499782rem 0;
      grid-template-rows: 0 minmax(1.69560038rem, max-content) minmax(2.87737335rem, max-content) minmax(1.83694702rem, max-content);
  }

  #EUGlla4aWTH7F2vY {
      grid-area: 3 / 3 / 5 / 4;
      position: relative;
  }

  #M26r7EuNKRfQjP1f {
      grid-template-columns: 5.62225476rem 32.98233057rem 22.79082934rem 32.98233057rem 5.62225476rem;
      grid-template-rows: minmax(11.03806406rem, max-content) minmax(32.45081909rem, max-content) minmax(1.69560038rem, max-content) minmax(4.71432037rem, max-content) minmax(6.32374368rem, max-content);
  }

  #qxi1dJYpuEgQ3GAP {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #FFamOyY9L43hFdCg {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hgmCugqHt6bM7JSf {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #sbid0hVA5U1i60dK {
      font-size: calc(3.12344802em - var(--ffsd));
  }

  #td6EGz77roQlxnv3 {
      --first-font-size: 3.12344802em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #WemUxubcgv2ggcRw {
      min-width: 50.46913017rem;
  }

  #NwkRNePE1Q9SWrKB {
      grid-area: 2 / 2 / 3 / 6;
      position: relative;
  }

  #Ty23ULE0tB2Nvpea {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #hkg6wjagLVyf04N9 {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #vqV4OOlDqnBupciK {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #ic2Hde6op0tE3pOV {
      --first-font-size: 2.24499268em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.24);
      margin-bottom: calc(var(--last-font-size) * -0.24);
  }

  #nYolEmHEUkq6c9dk {
      min-width: 53.77307016rem;
  }

  #ZRGfJC8CVYOn4ICt {
      grid-area: 4 / 3 / 5 / 7;
      position: relative;
  }

  #Pg1H9KHcUrYWirzx {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #u97ggHtTl5CjCFr2 {
      font-size: calc(2.40915081em - var(--ffsd));
  }

  #NhjFutAzLTBEK3N4 {
      --first-font-size: 2.40915081em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #MUvZvHoZnbpQy2Fg {
      min-width: 21.5893534rem;
  }

  #KxUBSgbSii92WswK {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #ijhRrpO0ZVZxzsH2 {
      grid-template-columns: 0 1.16722813rem 21.48518673rem 1.16722813rem;
      grid-template-rows: 0 minmax(1.86581736rem, max-content) minmax(2.88566618rem, max-content) minmax(1.94779105rem, max-content);
  }

  #JE09gLAD0lOkMTkB {
      grid-area: 6 / 4 / 7 / 5;
      position: relative;
  }

  #OvvMkFI95PY5lI31 {
      grid-template-columns: 5.62225476rem 0 0.6803525rem 23.81964299rem 25.86496801rem 3.30393999rem 40.70884175rem;
      grid-template-rows: minmax(12.70186915rem, max-content) minmax(10.06832064rem, max-content) minmax(2.20763838rem, max-content) minmax(10.12298917rem, max-content) minmax(5.21125733rem, max-content) minmax(6.69927459rem, max-content) minmax(9.21119832rem, max-content);
  }

  #Cy1PaoWfviQxRs5a {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #RmijFNceVqO7Fs3J {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #y1Vye7h8aQaaCSRZ {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #EsHH1pIVIoFxMfcb {
      font-size: calc(2.84680844em - var(--ffsd));
  }

  #jzrAYhXhr34yZVBt {
      --first-font-size: 2.84680844em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #mg6YZjNYcDGsDEM9 {
      min-width: 50.62255615rem;
  }

  #QRqrShqvd6dgCaht {
      grid-area: 2 / 2 / 3 / 4;
      position: relative;
  }

  #JIuf7v0lKOXoVVAx {
      font-size: calc(1.89786755em - var(--ffsd));
  }

  #RIKncFyr9pIUSUie {
      --first-font-size: 1.89786755em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #u9MNcVEjAOZ3rGlU {
      min-width: 50.62255615rem;
  }

  #jXpmqkj2JmUatY0n {
      grid-area: 4 / 3 / 5 / 5;
      position: relative;
  }

  #Iikie6NHQzNDHWuB {
      grid-template-columns: 0 0 50.51838948rem 0;
      grid-template-rows: 0 minmax(3.35923395rem, max-content) minmax(1.10534723rem, max-content) minmax(4.8870221rem, max-content);
  }

  #dsGaowxU9EVzl1tT {
      grid-area: 3 / 2 / 4 / 18;
      position: relative;
  }

  #MIRJ2qV9jGAzI4nY {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #HbBuYw0oCiveIm75 {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #gNgIpczgkfSqPrT7 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #WscXJmmrtcwvsJRP {
      min-width: 18.79626321rem;
  }

  #c13F6904MVKNpyVT {
      grid-area: 5 / 3 / 8 / 12;
      position: relative;
  }

  #U85i265wNQbw8T6A {
      grid-area: 6 / 14 / 7 / 19;
      position: relative;
  }

  #fGXtQn2TxF0u8sQK {
      grid-area: 9 / 4 / 10 / 23;
      position: relative;
  }

  #iBI7gsSYLx6PsCWJ {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #TQqu6u0aLQxKLqIb {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #LjHLSRvoqG4r58Lk {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #QhGfiNC4tKlmqZXA {
      min-width: 13.20411634rem;
  }

  #qtZ1Kp35L1YFOMrC {
      grid-area: 11 / 5 / 14 / 11;
      position: relative;
  }

  #onNVpINpFa5zgEgR {
      grid-area: 12 / 15 / 13 / 20;
      position: relative;
  }

  #WOFSN6jl6Ycg0BXK {
      grid-area: 15 / 6 / 16 / 24;
      position: relative;
  }

  #WwdPYj5hi5OJEaQS {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #CMjC5N8UaaUdalAx {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #m4k18ITxxsL9QFGM {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #L89XGpgiQRagWZxL {
      min-width: 29.6345801rem;
  }

  #sfuRSbOq2STMCRnA {
      grid-area: 17 / 7 / 20 / 13;
      position: relative;
  }

  #uWlILl6qsIML3Abq {
      grid-area: 18 / 16 / 19 / 21;
      position: relative;
  }

  #GxLZcKsgC2Hilfjd {
      grid-area: 21 / 8 / 22 / 25;
      position: relative;
  }

  #aQWCknqjztYHpTod {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #XXC7EZjp69bNCAIa {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #vpnVdpcR5ZSWo8T3 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #mUHVS577UYfY5gke {
      min-width: 12.98857712rem;
  }

  #gRtoAZXOZ73ARV3Y {
      grid-area: 23 / 9 / 26 / 10;
      position: relative;
  }

  #SvMKEAw6mQYt3EGi {
      grid-area: 24 / 17 / 25 / 22;
      position: relative;
  }

  #VptxBkH6YTytsvBQ {
      grid-area: 2 / 26 / 27 / 27;
      position: relative;
  }

  #rF78NrtcKVQUrqZt {
      grid-template-columns: 3.9691309rem 1.65312386rem 0 0 0 0 0 0 12.88441045rem 0.21553922rem 5.59214688rem 10.83831689rem 17.56701623rem 0 0 0 1.76783596rem 0 0 0 0 1.54449348rem 0 0 3.56702802rem 34.77870336rem 5.62225476rem;
      grid-template-rows: minmax(5.62225476rem, max-content) minmax(6.20255199rem, max-content) minmax(9.35160328rem, max-content) minmax(5.0984772rem, max-content) minmax(0.68549779rem, max-content) minmax(1.76783596rem, max-content) minmax(1.41196786rem, max-content) minmax(1.24450952rem, max-content) minmax(0.26842511rem, max-content) minmax(1.31771596rem, max-content) minmax(0.93210419rem, max-content) minmax(1.76783596rem, max-content) minmax(1.16536146rem, max-content) minmax(1.22388171rem, max-content) minmax(0.26842511rem, max-content) minmax(1.46412884rem, max-content) minmax(1.06698088rem, max-content) minmax(1.76783596rem, max-content) minmax(1.03048477rem, max-content) minmax(1.31771596rem, max-content) minmax(0.26842511rem, max-content) minmax(1.49097184rem, max-content) minmax(0.77842787rem, max-content) minmax(1.76783596rem, max-content) minmax(1.31903778rem, max-content) 0 minmax(5.62225476rem, max-content);
  }

  #IGQgWz9buyfmxwOX {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #RBLku5bRpfbZzoL5 {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #moYcaMc9ZCjaQ5Kk {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #l77XCv5ipqp7OSGG {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #tldXqR85ukzB2Xpc {
      font-size: calc(2.06930454em - var(--ffsd));
  }

  #v8dAWh4by7M5pxbI {
      font-size: calc(2.06930454em - var(--ffsd));
  }

  #c7XZiZxPxw03OdfC {
      --first-font-size: 2.06930454em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #mAkg4ABrRdFOVI2J {
      min-width: 19.05102545rem;
  }

  #Pwn7cfpx3FxXo8Mo {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #Z1C31VCWpC0VLV2V {
      grid-template-columns: 0 0.80527086rem 18.94685878rem 0.87847731rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(23.97657321rem, max-content) minmax(4.86802466rem, max-content);
  }

  #u8PbdJDPhGxEhVYX {
      grid-area: 3 / 2 / 9 / 3;
      position: relative;
  }

  #OjAxnq7DlESlsM2B {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #MaNyMNNQPmAYqeNO {
      font-size: calc(2.07392489em - var(--ffsd));
  }

  #ksBwM714QSw4PcGn {
      font-size: calc(2.07392489em - var(--ffsd));
  }

  #KI6UGKNb7ekfusHH {
      --first-font-size: 2.07392489em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #pwOsh6myIKro0Zxz {
      min-width: 18.1876581rem;
  }

  #oVRfPyZBKj86KKEy {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #a2VFO8DotspSemB7 {
      grid-template-columns: 0 1.10293386rem 18.08349143rem 0.94511022rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(23.67254475rem, max-content) minmax(5.17205312rem, max-content);
  }

  #QhxDFEMAfBE4jrLj {
      grid-area: 4 / 4 / 10 / 5;
      position: relative;
  }

  #i5SQlFa08mWt8OU7 {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #nHe50ftLL79P3xgb {
      font-size: calc(2.1473851em - var(--ffsd));
  }

  #BAHZRzIaByTsgLKw {
      font-size: calc(0.78090044em - var(--ffsd));
  }

  #YCRd8V2uExBvjkWO {
      --first-font-size: 2.1473851em;
      --last-font-size: 0.78090044em;
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #HDEyyYz0SekFT46Y {
      min-width: 11.86061229rem;
  }

  #CavGOY9hjCOkcDp6 {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #v9Bn2VIBikYtngpe {
      grid-template-columns: 0 0 11.75644562rem 0;
      grid-template-rows: 0 minmax(4.33626135rem, max-content) minmax(3.74182372rem, max-content) minmax(6.87446899rem, max-content);
  }

  #oK5ft2mais3eEpdb {
      grid-area: 2 / 11 / 5 / 12;
      position: relative;
  }

  #kI5ApGItmf85yhck {
      grid-area: 6 / 6 / 11 / 9;
      position: relative;
  }

  #cnFEflgiysujhtx6 {
      font-size: calc(2.23152334em - var(--ffsd));
  }

  #RiegmrffEAK6Ib0v {
      font-size: calc(1.78990963em - var(--ffsd));
  }

  #J4wEpTeqRP5K1HcN {
      font-size: calc(1.78990963em - var(--ffsd));
  }

  #r3V5CUIdBRTJnFOm {
      --first-font-size: 2.23152334em;
      --last-font-size: 1.78990963em;
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #mPGRDzyWfG82Z5P8 {
      min-width: 18.15383377rem;
  }

  #UXbjzZC3YybOVETT {
      grid-area: 8 / 7 / 13 / 8;
      position: relative;
  }

  #Nd6DmbZ6aBQIRg0a {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #rgkJVqu4suGcTb7S {
      font-size: calc(2.23152269em - var(--ffsd));
  }

  #evcD5UXFTfvykY49 {
      font-size: calc(1.8740776em - var(--ffsd));
  }

  #YsqZH9U9kIXmnuMA {
      --first-font-size: 2.23152269em;
      --last-font-size: 1.8740776em;
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #YlGLAzV42ZwrciJk {
      min-width: 18.15383377rem;
  }

  #Wpq2384czQxH5ar0 {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #wKILIpJB3Xh5vn2P {
      grid-template-columns: 0 0.8228348rem 18.0496671rem 0.8228348rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(24.93320827rem, max-content) minmax(3.9113896rem, max-content);
  }

  #jPrUqChMZEzXArN7 {
      grid-area: 7 / 10 / 12 / 13;
      position: relative;
  }

  #Sa7Jx5tZzWs5J5nY {
      grid-template-columns: 5.79217985rem 20.63060695rem 2.34260615rem 20.13153551rem 2.59398003rem 1.19238944rem 18.0496671rem 1.35290664rem 1.97657394rem 7.93889108rem 11.75644562rem 0 6.2422177rem;
      grid-template-rows: 0 minmax(14.95255405rem, max-content) 0 0 0 0 minmax(6.80314091rem, max-content) minmax(28.84459787rem, max-content) 0 0 0 minmax(0.42608356rem, max-content) minmax(5.1961712rem, max-content);
  }

  #zwKgah8dqbXVOUpH {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #SggXgrATxLg27eBg {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #T3PpJIYdrbgl5nVF {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #Cmsk5UCmTRIxuVBf {
      grid-area: 3 / 4 / 5 / 7;
      position: relative;
  }

  #X8l0aTR5IlPmwv6M {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hOE8WAqz5b9ndbqd {
      grid-template-columns: 0 44.39589498rem;
      grid-template-rows: 0 minmax(33.97268383rem, max-content);
  }

  #EONmJlhFuBfu0xiM {
      grid-area: 4 / 2 / 6 / 6;
      position: relative;
  }

  #G5XcDjTvJfhcL1KU {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #Wt9TTYELXBum9yxE {
      font-size: calc(2.45912884em - var(--ffsd));
  }

  #sJEemP9xMe2ftdkJ {
      --first-font-size: 2.45912884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #q8ggHWJBcXqflX2C {
      min-width: 22.03504359rem;
  }

  #qSHJbRetCVKoRsdO {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #Rf1saeE2U1lQU1vt {
      grid-template-columns: 0 0 21.93087692rem 0.01242755rem;
      grid-template-rows: 0 minmax(1.5711768rem, max-content) minmax(3.02208346rem, max-content) minmax(1.57829413rem, max-content);
  }

  #KtETJnG6VhxbQeN3 {
      grid-area: 7 / 3 / 8 / 5;
      position: relative;
  }

  #W12Mp8BOWiruKw9c {
      font-size: calc(2.92825769em - var(--ffsd));
  }

  #xNnCswL01UvjYUMd {
      --first-font-size: 2.92825769em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #c2ZiFqm2RsHSfKuj {
      min-width: 24.99376909rem;
  }

  #ykzECYCWLJWIMnLZ {
      grid-area: 2 / 2 / 3 / 4;
      position: relative;
  }

  #RsdNPazz5xf8WxLz {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #V8kGV1eLT5Tr5VZq {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #xDjh9qFYQ29yPKXW {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #I4jckjq6QSn0pIBp {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #kzdHaTA9PJVl4OaB {
      --first-font-size: 1.95215959em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #XexK9M4YS6jTwRSB {
      min-width: 24.99376909rem;
  }

  #OK5e9K9ewpI2JMKx {
      grid-area: 4 / 3 / 5 / 5;
      position: relative;
  }

  #w1wHkC1Yu5MxO1LO {
      grid-template-columns: 0 0 24.88960242rem 0;
      grid-template-rows: 0 minmax(3.45534407rem, max-content) minmax(2.55526197rem, max-content) minmax(45.6564202rem, max-content);
  }

  #OK1vTNfjGM1ov4vV {
      grid-area: 2 / 8 / 9 / 9;
      position: relative;
  }

  #wqYjr9PTCOkGmL0C {
      grid-template-columns: 5.60410502rem 19.0615714rem 3.90304349rem 18.04026098rem 3.3910191rem 15.21492213rem 2.70986191rem 24.88960242rem 7.18561354rem;
      grid-template-rows: minmax(10.09455456rem, max-content) minmax(1.43804374rem, max-content) minmax(4.48094564rem, max-content) minmax(18.76945765rem, max-content) minmax(15.20322618rem, max-content) minmax(4.81737686rem, max-content) minmax(6.17155438rem, max-content) minmax(0.7864218rem, max-content) minmax(10.09455456rem, max-content);
  }

  #T2NMkGKZOUkwAcmX {
      min-height: calc(17.56051855 * var(--1vh, 1vh));
  }

  #vAFmLVjpViOO8pEh {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #NdSP5GhekaOasljF {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #N6VpBGKRcBI8WMxl {
      font-size: calc(2.92825769em - var(--ffsd));
  }

  #ULaD2Y01Sk5mMOak {
      --first-font-size: 2.92825769em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #nRfnhX0YzOaQ0Xdw {
      min-width: 26.36875233rem;
  }

  #eRPPNza9h5HcdgF7 {
      grid-area: 2 / 2 / 3 / 9;
      position: relative;
  }

  #oHXHqNq8CxsueFnV {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #bylecg2blPm2OwBL {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #koN7beDlYDRe7jfg {
      min-width: 26.36875233rem;
  }

  #owTAQ0z6seFDL7zt {
      grid-area: 4 / 3 / 5 / 10;
      position: relative;
  }

  #n6ALYRGoNIPBU9e2 {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #E5A0QPuBdhE3VROO {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #vUxUbys62KfUqI6i {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #ZV7uUR4i3lj84q73 {
      min-width: 26.36875233rem;
  }

  #MRdQu4FBvWGPGryX {
      grid-area: 6 / 4 / 7 / 11;
      position: relative;
  }

  #aTIpy788F0YNw6gv {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #FBw4YWZt1Y5OIuqw {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #sXAaBpS0zx4H2vOJ {
      min-width: 26.36875233rem;
  }

  #BGFMS2RR1T9II4YV {
      grid-area: 8 / 5 / 9 / 12;
      position: relative;
  }

  #BYPYCHDU7OlSjV2c {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #OMWWFAeGDtRPV7Sb {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #mO4wzJEPpkxOftS4 {
      min-width: 26.36875233rem;
  }

  #bxYt3OgDKC4xflmY {
      grid-area: 10 / 6 / 11 / 13;
      position: relative;
  }

  #XkHSwDE7oqwMyWL7 {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #pFHmlClCkB6kdaez {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #pD0kzGq1kLx54a2S {
      min-width: 26.36875233rem;
  }

  #hpeyZ92ab31eREpU {
      grid-area: 12 / 7 / 13 / 14;
      position: relative;
  }

  #iLAnHYrLq0AZ1fbe {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #fVzlRlIKSIjHpp2J {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #jiNcs7LojO8ChMNY {
      min-width: 26.36875233rem;
  }

  #TDcUqR484nocyt77 {
      grid-area: 14 / 8 / 15 / 15;
      position: relative;
  }

  #ejASdF8seWdXb62i {
      grid-template-columns: 0 0 0 0 0 0 0 26.26458566rem 0 0 0 0 0 0;
      grid-template-rows: 0 minmax(3.45534407rem, max-content) minmax(3.18272207rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(3.80673499rem, max-content) minmax(1.98516502rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(1.75695461rem, max-content) minmax(1.98516502rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(1.75695461rem, max-content);
  }

  #WsXKJi0tUQy6M0Yj {
      grid-area: 3 / 2 / 4 / 5;
      position: relative;
  }

  #o103wLUjzjdJ0vbj {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #bLn45zJKclaSXxXB {
      font-size: calc(2.14738653em - var(--ffsd));
  }

  #gWwroNFESTLBcPfT {
      --first-font-size: 2.14738653em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #YWW3MOpmOgfb841P {
      min-width: 19.25489714rem;
  }

  #JCjXP2hfxHABIryt {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #cptnjYzeKMbd7tD2 {
      grid-template-columns: 0 0 19.15073048rem 0;
      grid-template-rows: 0 minmax(1.37047484rem, max-content) minmax(2.64519327rem, max-content) minmax(1.37047484rem, max-content);
  }

  #dNV7QOHJmBMIR8do {
      grid-area: 5 / 3 / 8 / 4;
      position: relative;
  }

  #ODW2Zr4KWDqq5gBJ {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #K0gdNuRVqJ4C0sMP {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #J3wv3cJg8jTi4Gn6 {
      grid-template-columns: 0 18.61661692rem 23.99889497rem 18.61661692rem;
      grid-template-rows: 0 0 minmax(30.47749495rem, max-content) minmax(3.99765807rem, max-content);
  }

  #AW5WKBsna8IRStre {
      grid-area: 2 / 6 / 6 / 9;
      position: relative;
  }

  #ExVlBTD6zGtQxqgL {
      grid-area: 2 / 2 / 7 / 7;
      position: relative;
  }

  #UMhTMNGScmWqTQH2 {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #FIC9eZslsweKSBg0 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #uWXcr0A1k6OazN9g {
      min-width: 23.97028705rem;
  }

  #bAa0QtZVlTHParfB {
      grid-area: 4 / 3 / 5 / 4;
      position: relative;
  }

  #MU7RIMCyCaEqHZOq {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #Cwnw3RXYBtarBT3W {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #DmTeAPJIN9S3eeqO {
      grid-template-columns: 0 0.8965513rem 1.59410699rem 0.84795565rem;
      grid-template-rows: 0 minmax(0.87225348rem, max-content) minmax(1.59410699rem, max-content) minmax(0.87225348rem, max-content);
  }

  #zbHp22QpXxxTF8oc {
      grid-template-columns: 0 4.47419318rem 23.86612039rem 2.19283912rem 3.33861395rem 5.64337528rem;
      grid-template-rows: 0 minmax(0.9399522rem, max-content) minmax(0.61513347rem, max-content) minmax(2.108347rem, max-content) minmax(0.61513347rem, max-content) minmax(1.39410641rem, max-content);
  }

  #ysr65jjvWIT1gbxc {
      grid-area: 7 / 7 / 9 / 8;
      position: relative;
  }

  #eIdYn4hrBVqteSux {
      grid-template-columns: 5.62225476rem 1.65312808rem 19.15073048rem 5.4607271rem 2.37352442rem 10.85849344rem 39.51514192rem 10.85849344rem 4.50750636rem;
      grid-template-rows: minmax(12.87252632rem, max-content) minmax(1.97462047rem, max-content) minmax(26.528254rem, max-content) minmax(3.83874909rem, max-content) minmax(2.13352945rem, max-content) minmax(1.29364199rem, max-content) minmax(1.95897151rem, max-content) minmax(3.71370105rem, max-content) minmax(1.90855371rem, max-content);
  }

  #c85MiQ8j8pyAFfoz {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }
}

@media (min-width: 480.05px) and (max-width: 768px) {
  #Gcip3IHrXqoSSerB {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #R4bFHk3K9MhsUuXQ {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #uB9gdUBrmjPAQVE6 {
      grid-area: 4 / 3 / 5 / 4;
      position: relative;
  }

  #xYbBrx6mafzrIqCT {
      font-size: calc(2.62903367em - var(--ffsd));
  }

  #AnOXjoZUpH4TNBbq {
      --first-font-size: 2.62903367em;
      --last-font-size: var(--first-font-size);
      transform: translateX(calc(var(--first-font-size) * 0.02));
      margin-top: calc(var(--first-font-size) * 0.04);
      margin-bottom: calc(var(--last-font-size) * 0.04);
  }

  #FX2sUpNG3cn48QTY {
      min-width: 28.56375722rem;
  }

  #BL7fbA4BBx5IpnqI {
      grid-area: 6 / 2 / 7 / 5;
      position: relative;
  }

  #okdJh55tKLEwvioS {
      grid-area: 2 / 6 / 8 / 7;
      position: relative;
  }

  #Hj2vCWH2yaYdooEL {
      grid-area: 2 / 2 / 9 / 8;
      position: relative;
  }

  #hNM83Z741TiR9kHy {
      grid-area: 3 / 3 / 4 / 6;
      position: relative;
  }

  #gj4YJ4yvRxKWtsAH {
      grid-area: 5 / 5 / 6 / 7;
      position: relative;
  }

  #gdFbF9eUNpIBUzs1 {
      grid-area: 7 / 4 / 8 / 9;
      position: relative;
  }

  #aa2J8LgSNASnS01p {
      grid-template-columns: 0 1.62960034rem 3.10884135rem 8.02863446rem 18.54622249rem 1.62803783rem 0.00156251rem 0;
      grid-template-rows: 0 minmax(2.57868613rem, max-content) minmax(49.84863032rem, max-content) minmax(0.95167889rem, max-content) minmax(0.29282577rem, max-content) minmax(1.45157349rem, max-content) minmax(0.44029501rem, max-content) minmax(0.65885798rem, max-content);
  }

  #mDREwzGjCETykcMJ {
      grid-area: 3 / 8 / 9 / 9;
      position: relative;
  }

  #yH1jqxz2dwWbkSRs {
      grid-template-columns: 2.8077745rem 5.59080714rem 17.31703877rem 5.59080714rem 3.68386429rem 32.06680918rem 0 32.94289898rem;
      grid-template-rows: 0 0 minmax(2.90260036rem, max-content) minmax(21.72073144rem, max-content) minmax(2.74923952rem, max-content) minmax(14.85161113rem, max-content) minmax(13.99836513rem, max-content) 0;
  }

  #EHJe4a9TjZnF5oTb {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #ZvAIGWL199mtdQty {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hd9DCGsDJbnCUTMH {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #p2klDFBhgs2IIzu0 {
      font-size: calc(4.00191801em - var(--ffsd));
  }

  #L6b9uLo7BkyIwbjg {
      font-size: calc(2.73303807em - var(--ffsd));
  }

  #AB5tuhlSHL4A61dS {
      --first-font-size: 4.00191801em;
      --last-font-size: 2.73303807em;
      margin-top: calc(var(--first-font-size) * -0.18);
      margin-bottom: calc(var(--last-font-size) * -0.18);
  }

  #NDPGsVcVomdqV1BM {
      min-width: 88.82059465rem;
  }

  #r90enHsYajhqod6n {
      grid-area: 2 / 2 / 4 / 5;
      position: relative;
  }

  #EBbqjM05jht5l4qT {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #Zh5lAIkgkw3mQD23 {
      font-size: calc(2.45061493em - var(--ffsd));
  }

  #KA1ruxmY3fDQ0CHz {
      --first-font-size: 2.45061493em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #ULKpIAXmD5Yo6ZUm {
      min-width: 21.92010198rem;
  }

  #AkuMzJ6ee2hQPfpP {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #oRCzniDlPNnYzH32 {
      grid-template-columns: 0 0.93583152rem 21.85499782rem 0;
      grid-template-rows: 0 minmax(1.69560038rem, max-content) minmax(2.87737335rem, max-content) minmax(1.83694702rem, max-content);
  }

  #EUGlla4aWTH7F2vY {
      grid-area: 3 / 3 / 5 / 4;
      position: relative;
  }

  #M26r7EuNKRfQjP1f {
      grid-template-columns: 5.62225476rem 32.98233057rem 22.79082934rem 32.98233057rem 5.62225476rem;
      grid-template-rows: minmax(11.03806406rem, max-content) minmax(32.45081909rem, max-content) minmax(1.69560038rem, max-content) minmax(4.71432037rem, max-content) minmax(6.32374368rem, max-content);
  }

  #qxi1dJYpuEgQ3GAP {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #FFamOyY9L43hFdCg {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hgmCugqHt6bM7JSf {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #sbid0hVA5U1i60dK {
      font-size: calc(3.12344802em - var(--ffsd));
  }

  #td6EGz77roQlxnv3 {
      --first-font-size: 3.12344802em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #WemUxubcgv2ggcRw {
      min-width: 50.43006767rem;
  }

  #NwkRNePE1Q9SWrKB {
      grid-area: 2 / 2 / 3 / 6;
      position: relative;
  }

  #Ty23ULE0tB2Nvpea {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #hkg6wjagLVyf04N9 {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #vqV4OOlDqnBupciK {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #ic2Hde6op0tE3pOV {
      --first-font-size: 2.24499268em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.24);
      margin-bottom: calc(var(--last-font-size) * -0.24);
  }

  #nYolEmHEUkq6c9dk {
      min-width: 53.73400766rem;
  }

  #ZRGfJC8CVYOn4ICt {
      grid-area: 4 / 3 / 5 / 7;
      position: relative;
  }

  #Pg1H9KHcUrYWirzx {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #u97ggHtTl5CjCFr2 {
      font-size: calc(2.40915081em - var(--ffsd));
  }

  #NhjFutAzLTBEK3N4 {
      --first-font-size: 2.40915081em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #MUvZvHoZnbpQy2Fg {
      min-width: 21.5502909rem;
  }

  #KxUBSgbSii92WswK {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #ijhRrpO0ZVZxzsH2 {
      grid-template-columns: 0 1.16722813rem 21.48518673rem 1.16722813rem;
      grid-template-rows: 0 minmax(1.86581736rem, max-content) minmax(2.88566618rem, max-content) minmax(1.94779105rem, max-content);
  }

  #JE09gLAD0lOkMTkB {
      grid-area: 6 / 4 / 7 / 5;
      position: relative;
  }

  #OvvMkFI95PY5lI31 {
      grid-template-columns: 5.62225476rem 0 0.6803525rem 23.81964299rem 25.86496801rem 3.30393999rem 40.70884175rem;
      grid-template-rows: minmax(12.70186915rem, max-content) minmax(10.06832064rem, max-content) minmax(2.20763838rem, max-content) minmax(10.12298917rem, max-content) minmax(5.21125733rem, max-content) minmax(6.69927459rem, max-content) minmax(9.21119832rem, max-content);
  }

  #Cy1PaoWfviQxRs5a {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #RmijFNceVqO7Fs3J {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #y1Vye7h8aQaaCSRZ {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #EsHH1pIVIoFxMfcb {
      font-size: calc(2.84680844em - var(--ffsd));
  }

  #jzrAYhXhr34yZVBt {
      --first-font-size: 2.84680844em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #mg6YZjNYcDGsDEM9 {
      min-width: 50.58349365rem;
  }

  #QRqrShqvd6dgCaht {
      grid-area: 2 / 2 / 3 / 4;
      position: relative;
  }

  #JIuf7v0lKOXoVVAx {
      font-size: calc(1.89786755em - var(--ffsd));
  }

  #RIKncFyr9pIUSUie {
      --first-font-size: 1.89786755em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #u9MNcVEjAOZ3rGlU {
      min-width: 50.58349365rem;
  }

  #jXpmqkj2JmUatY0n {
      grid-area: 4 / 3 / 5 / 5;
      position: relative;
  }

  #Iikie6NHQzNDHWuB {
      grid-template-columns: 0 0 50.51838948rem 0;
      grid-template-rows: 0 minmax(3.35923395rem, max-content) minmax(1.10534723rem, max-content) minmax(4.8870221rem, max-content);
  }

  #dsGaowxU9EVzl1tT {
      grid-area: 3 / 2 / 4 / 18;
      position: relative;
  }

  #MIRJ2qV9jGAzI4nY {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #HbBuYw0oCiveIm75 {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #gNgIpczgkfSqPrT7 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #WscXJmmrtcwvsJRP {
      min-width: 18.75720071rem;
  }

  #c13F6904MVKNpyVT {
      grid-area: 5 / 3 / 8 / 12;
      position: relative;
  }

  #U85i265wNQbw8T6A {
      grid-area: 6 / 14 / 7 / 19;
      position: relative;
  }

  #fGXtQn2TxF0u8sQK {
      grid-area: 9 / 4 / 10 / 23;
      position: relative;
  }

  #iBI7gsSYLx6PsCWJ {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #TQqu6u0aLQxKLqIb {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #LjHLSRvoqG4r58Lk {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #QhGfiNC4tKlmqZXA {
      min-width: 13.16505384rem;
  }

  #qtZ1Kp35L1YFOMrC {
      grid-area: 11 / 5 / 14 / 11;
      position: relative;
  }

  #onNVpINpFa5zgEgR {
      grid-area: 12 / 15 / 13 / 20;
      position: relative;
  }

  #WOFSN6jl6Ycg0BXK {
      grid-area: 15 / 6 / 16 / 24;
      position: relative;
  }

  #WwdPYj5hi5OJEaQS {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #CMjC5N8UaaUdalAx {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #m4k18ITxxsL9QFGM {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #L89XGpgiQRagWZxL {
      min-width: 29.5955176rem;
  }

  #sfuRSbOq2STMCRnA {
      grid-area: 17 / 7 / 20 / 13;
      position: relative;
  }

  #uWlILl6qsIML3Abq {
      grid-area: 18 / 16 / 19 / 21;
      position: relative;
  }

  #GxLZcKsgC2Hilfjd {
      grid-area: 21 / 8 / 22 / 25;
      position: relative;
  }

  #aQWCknqjztYHpTod {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #XXC7EZjp69bNCAIa {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #vpnVdpcR5ZSWo8T3 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #mUHVS577UYfY5gke {
      min-width: 12.94951462rem;
  }

  #gRtoAZXOZ73ARV3Y {
      grid-area: 23 / 9 / 26 / 10;
      position: relative;
  }

  #SvMKEAw6mQYt3EGi {
      grid-area: 24 / 17 / 25 / 22;
      position: relative;
  }

  #VptxBkH6YTytsvBQ {
      grid-area: 2 / 26 / 27 / 27;
      position: relative;
  }

  #rF78NrtcKVQUrqZt {
      grid-template-columns: 3.9691309rem 1.65312386rem 0 0 0 0 0 0 12.88441045rem 0.21553922rem 5.59214688rem 10.83831689rem 17.56701623rem 0 0 0 1.76783596rem 0 0 0 0 1.54449348rem 0 0 3.56702802rem 34.77870336rem 5.62225476rem;
      grid-template-rows: minmax(5.62225476rem, max-content) minmax(6.20255199rem, max-content) minmax(9.35160328rem, max-content) minmax(5.0984772rem, max-content) minmax(0.68549779rem, max-content) minmax(1.76783596rem, max-content) minmax(1.41196786rem, max-content) minmax(1.24450952rem, max-content) minmax(0.26842511rem, max-content) minmax(1.31771596rem, max-content) minmax(0.93210419rem, max-content) minmax(1.76783596rem, max-content) minmax(1.16536146rem, max-content) minmax(1.22388171rem, max-content) minmax(0.26842511rem, max-content) minmax(1.46412884rem, max-content) minmax(1.06698088rem, max-content) minmax(1.76783596rem, max-content) minmax(1.03048477rem, max-content) minmax(1.31771596rem, max-content) minmax(0.26842511rem, max-content) minmax(1.49097184rem, max-content) minmax(0.77842787rem, max-content) minmax(1.76783596rem, max-content) minmax(1.31903778rem, max-content) 0 minmax(5.62225476rem, max-content);
  }

  #IGQgWz9buyfmxwOX {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #RBLku5bRpfbZzoL5 {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #moYcaMc9ZCjaQ5Kk {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #l77XCv5ipqp7OSGG {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #tldXqR85ukzB2Xpc {
      font-size: calc(2.06930454em - var(--ffsd));
  }

  #v8dAWh4by7M5pxbI {
      font-size: calc(2.06930454em - var(--ffsd));
  }

  #c7XZiZxPxw03OdfC {
      --first-font-size: 2.06930454em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #mAkg4ABrRdFOVI2J {
      min-width: 19.01196295rem;
  }

  #Pwn7cfpx3FxXo8Mo {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #Z1C31VCWpC0VLV2V {
      grid-template-columns: 0 0.80527086rem 18.94685878rem 0.87847731rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(23.97657321rem, max-content) minmax(4.86802466rem, max-content);
  }

  #u8PbdJDPhGxEhVYX {
      grid-area: 3 / 2 / 9 / 3;
      position: relative;
  }

  #OjAxnq7DlESlsM2B {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #MaNyMNNQPmAYqeNO {
      font-size: calc(2.07392489em - var(--ffsd));
  }

  #ksBwM714QSw4PcGn {
      font-size: calc(2.07392489em - var(--ffsd));
  }

  #KI6UGKNb7ekfusHH {
      --first-font-size: 2.07392489em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #pwOsh6myIKro0Zxz {
      min-width: 18.1485956rem;
  }

  #oVRfPyZBKj86KKEy {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #a2VFO8DotspSemB7 {
      grid-template-columns: 0 1.10293386rem 18.08349143rem 0.94511022rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(23.67254475rem, max-content) minmax(5.17205312rem, max-content);
  }

  #QhxDFEMAfBE4jrLj {
      grid-area: 4 / 4 / 10 / 5;
      position: relative;
  }

  #i5SQlFa08mWt8OU7 {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #nHe50ftLL79P3xgb {
      font-size: calc(2.1473851em - var(--ffsd));
  }

  #BAHZRzIaByTsgLKw {
      font-size: calc(0.78090044em - var(--ffsd));
  }

  #YCRd8V2uExBvjkWO {
      --first-font-size: 2.1473851em;
      --last-font-size: 0.78090044em;
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #HDEyyYz0SekFT46Y {
      min-width: 11.82154979rem;
  }

  #CavGOY9hjCOkcDp6 {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #v9Bn2VIBikYtngpe {
      grid-template-columns: 0 0 11.75644562rem 0;
      grid-template-rows: 0 minmax(4.33626135rem, max-content) minmax(3.74182372rem, max-content) minmax(6.87446899rem, max-content);
  }

  #oK5ft2mais3eEpdb {
      grid-area: 2 / 11 / 5 / 12;
      position: relative;
  }

  #kI5ApGItmf85yhck {
      grid-area: 6 / 6 / 11 / 9;
      position: relative;
  }

  #cnFEflgiysujhtx6 {
      font-size: calc(2.23152334em - var(--ffsd));
  }

  #RiegmrffEAK6Ib0v {
      font-size: calc(1.78990963em - var(--ffsd));
  }

  #J4wEpTeqRP5K1HcN {
      font-size: calc(1.78990963em - var(--ffsd));
  }

  #r3V5CUIdBRTJnFOm {
      --first-font-size: 2.23152334em;
      --last-font-size: 1.78990963em;
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #mPGRDzyWfG82Z5P8 {
      min-width: 18.11477127rem;
  }

  #UXbjzZC3YybOVETT {
      grid-area: 8 / 7 / 13 / 8;
      position: relative;
  }

  #Nd6DmbZ6aBQIRg0a {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #rgkJVqu4suGcTb7S {
      font-size: calc(2.23152269em - var(--ffsd));
  }

  #evcD5UXFTfvykY49 {
      font-size: calc(1.8740776em - var(--ffsd));
  }

  #YsqZH9U9kIXmnuMA {
      --first-font-size: 2.23152269em;
      --last-font-size: 1.8740776em;
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #YlGLAzV42ZwrciJk {
      min-width: 18.11477127rem;
  }

  #Wpq2384czQxH5ar0 {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #wKILIpJB3Xh5vn2P {
      grid-template-columns: 0 0.8228348rem 18.0496671rem 0.8228348rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(24.93320827rem, max-content) minmax(3.9113896rem, max-content);
  }

  #jPrUqChMZEzXArN7 {
      grid-area: 7 / 10 / 12 / 13;
      position: relative;
  }

  #Sa7Jx5tZzWs5J5nY {
      grid-template-columns: 5.79217985rem 20.63060695rem 2.34260615rem 20.13153551rem 2.59398003rem 1.19238944rem 18.0496671rem 1.35290664rem 1.97657394rem 7.93889108rem 11.75644562rem 0 6.2422177rem;
      grid-template-rows: 0 minmax(14.95255405rem, max-content) 0 0 0 0 minmax(6.80314091rem, max-content) minmax(28.84459787rem, max-content) 0 0 0 minmax(0.42608356rem, max-content) minmax(5.1961712rem, max-content);
  }

  #zwKgah8dqbXVOUpH {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #SggXgrATxLg27eBg {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #T3PpJIYdrbgl5nVF {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #Cmsk5UCmTRIxuVBf {
      grid-area: 3 / 4 / 5 / 7;
      position: relative;
  }

  #X8l0aTR5IlPmwv6M {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hOE8WAqz5b9ndbqd {
      grid-template-columns: 0 44.39589498rem;
      grid-template-rows: 0 minmax(33.97268383rem, max-content);
  }

  #EONmJlhFuBfu0xiM {
      grid-area: 4 / 2 / 6 / 6;
      position: relative;
  }

  #G5XcDjTvJfhcL1KU {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #Wt9TTYELXBum9yxE {
      font-size: calc(2.45912884em - var(--ffsd));
  }

  #sJEemP9xMe2ftdkJ {
      --first-font-size: 2.45912884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #q8ggHWJBcXqflX2C {
      min-width: 21.99598109rem;
  }

  #qSHJbRetCVKoRsdO {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #Rf1saeE2U1lQU1vt {
      grid-template-columns: 0 0 21.93087692rem 0.01242755rem;
      grid-template-rows: 0 minmax(1.5711768rem, max-content) minmax(3.02208346rem, max-content) minmax(1.57829413rem, max-content);
  }

  #KtETJnG6VhxbQeN3 {
      grid-area: 7 / 3 / 8 / 5;
      position: relative;
  }

  #W12Mp8BOWiruKw9c {
      font-size: calc(2.92825769em - var(--ffsd));
  }

  #xNnCswL01UvjYUMd {
      --first-font-size: 2.92825769em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #c2ZiFqm2RsHSfKuj {
      min-width: 24.95470659rem;
  }

  #ykzECYCWLJWIMnLZ {
      grid-area: 2 / 2 / 3 / 4;
      position: relative;
  }

  #RsdNPazz5xf8WxLz {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #V8kGV1eLT5Tr5VZq {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #xDjh9qFYQ29yPKXW {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #I4jckjq6QSn0pIBp {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #kzdHaTA9PJVl4OaB {
      --first-font-size: 1.95215959em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #XexK9M4YS6jTwRSB {
      min-width: 24.95470659rem;
  }

  #OK5e9K9ewpI2JMKx {
      grid-area: 4 / 3 / 5 / 5;
      position: relative;
  }

  #w1wHkC1Yu5MxO1LO {
      grid-template-columns: 0 0 24.88960242rem 0;
      grid-template-rows: 0 minmax(3.45534407rem, max-content) minmax(2.55526197rem, max-content) minmax(45.6564202rem, max-content);
  }

  #OK1vTNfjGM1ov4vV {
      grid-area: 2 / 8 / 9 / 9;
      position: relative;
  }

  #wqYjr9PTCOkGmL0C {
      grid-template-columns: 5.60410502rem 19.0615714rem 3.90304349rem 18.04026098rem 3.3910191rem 15.21492213rem 2.70986191rem 24.88960242rem 7.18561354rem;
      grid-template-rows: minmax(10.09455456rem, max-content) minmax(1.43804374rem, max-content) minmax(4.48094564rem, max-content) minmax(18.76945765rem, max-content) minmax(15.20322618rem, max-content) minmax(4.81737686rem, max-content) minmax(6.17155438rem, max-content) minmax(0.7864218rem, max-content) minmax(10.09455456rem, max-content);
  }

  #T2NMkGKZOUkwAcmX {
      min-height: calc(17.56051855 * var(--1vh, 1vh));
  }

  #vAFmLVjpViOO8pEh {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #NdSP5GhekaOasljF {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #N6VpBGKRcBI8WMxl {
      font-size: calc(2.92825769em - var(--ffsd));
  }

  #ULaD2Y01Sk5mMOak {
      --first-font-size: 2.92825769em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #nRfnhX0YzOaQ0Xdw {
      min-width: 26.32968983rem;
  }

  #eRPPNza9h5HcdgF7 {
      grid-area: 2 / 2 / 3 / 9;
      position: relative;
  }

  #oHXHqNq8CxsueFnV {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #bylecg2blPm2OwBL {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #koN7beDlYDRe7jfg {
      min-width: 26.32968983rem;
  }

  #owTAQ0z6seFDL7zt {
      grid-area: 4 / 3 / 5 / 10;
      position: relative;
  }

  #n6ALYRGoNIPBU9e2 {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #E5A0QPuBdhE3VROO {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #vUxUbys62KfUqI6i {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #ZV7uUR4i3lj84q73 {
      min-width: 26.32968983rem;
  }

  #MRdQu4FBvWGPGryX {
      grid-area: 6 / 4 / 7 / 11;
      position: relative;
  }

  #aTIpy788F0YNw6gv {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #FBw4YWZt1Y5OIuqw {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #sXAaBpS0zx4H2vOJ {
      min-width: 26.32968983rem;
  }

  #BGFMS2RR1T9II4YV {
      grid-area: 8 / 5 / 9 / 12;
      position: relative;
  }

  #BYPYCHDU7OlSjV2c {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #OMWWFAeGDtRPV7Sb {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #mO4wzJEPpkxOftS4 {
      min-width: 26.32968983rem;
  }

  #bxYt3OgDKC4xflmY {
      grid-area: 10 / 6 / 11 / 13;
      position: relative;
  }

  #XkHSwDE7oqwMyWL7 {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #pFHmlClCkB6kdaez {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #pD0kzGq1kLx54a2S {
      min-width: 26.32968983rem;
  }

  #hpeyZ92ab31eREpU {
      grid-area: 12 / 7 / 13 / 14;
      position: relative;
  }

  #iLAnHYrLq0AZ1fbe {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #fVzlRlIKSIjHpp2J {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #jiNcs7LojO8ChMNY {
      min-width: 26.32968983rem;
  }

  #TDcUqR484nocyt77 {
      grid-area: 14 / 8 / 15 / 15;
      position: relative;
  }

  #ejASdF8seWdXb62i {
      grid-template-columns: 0 0 0 0 0 0 0 26.26458566rem 0 0 0 0 0 0;
      grid-template-rows: 0 minmax(3.45534407rem, max-content) minmax(3.18272207rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(3.80673499rem, max-content) minmax(1.98516502rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(1.75695461rem, max-content) minmax(1.98516502rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(1.75695461rem, max-content);
  }

  #WsXKJi0tUQy6M0Yj {
      grid-area: 3 / 2 / 4 / 5;
      position: relative;
  }

  #o103wLUjzjdJ0vbj {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #bLn45zJKclaSXxXB {
      font-size: calc(2.14738653em - var(--ffsd));
  }

  #gWwroNFESTLBcPfT {
      --first-font-size: 2.14738653em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #YWW3MOpmOgfb841P {
      min-width: 19.21583464rem;
  }

  #JCjXP2hfxHABIryt {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #cptnjYzeKMbd7tD2 {
      grid-template-columns: 0 0 19.15073048rem 0;
      grid-template-rows: 0 minmax(1.37047484rem, max-content) minmax(2.64519327rem, max-content) minmax(1.37047484rem, max-content);
  }

  #dNV7QOHJmBMIR8do {
      grid-area: 5 / 3 / 8 / 4;
      position: relative;
  }

  #ODW2Zr4KWDqq5gBJ {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #K0gdNuRVqJ4C0sMP {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #J3wv3cJg8jTi4Gn6 {
      grid-template-columns: 0 18.61661692rem 23.99889497rem 18.61661692rem;
      grid-template-rows: 0 0 minmax(30.47749495rem, max-content) minmax(3.99765807rem, max-content);
  }

  #AW5WKBsna8IRStre {
      grid-area: 2 / 6 / 6 / 9;
      position: relative;
  }

  #ExVlBTD6zGtQxqgL {
      grid-area: 2 / 2 / 7 / 7;
      position: relative;
  }

  #UMhTMNGScmWqTQH2 {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #FIC9eZslsweKSBg0 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #uWXcr0A1k6OazN9g {
      min-width: 23.93122455rem;
  }

  #bAa0QtZVlTHParfB {
      grid-area: 4 / 3 / 5 / 4;
      position: relative;
  }

  #MU7RIMCyCaEqHZOq {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #Cwnw3RXYBtarBT3W {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #DmTeAPJIN9S3eeqO {
      grid-template-columns: 0 0.8965513rem 1.59410699rem 0.84795565rem;
      grid-template-rows: 0 minmax(0.87225348rem, max-content) minmax(1.59410699rem, max-content) minmax(0.87225348rem, max-content);
  }

  #zbHp22QpXxxTF8oc {
      grid-template-columns: 0 4.47419318rem 23.86612039rem 2.19283912rem 3.33861395rem 5.64337528rem;
      grid-template-rows: 0 minmax(0.9399522rem, max-content) minmax(0.61513347rem, max-content) minmax(2.108347rem, max-content) minmax(0.61513347rem, max-content) minmax(1.39410641rem, max-content);
  }

  #ysr65jjvWIT1gbxc {
      grid-area: 7 / 7 / 9 / 8;
      position: relative;
  }

  #eIdYn4hrBVqteSux {
      grid-template-columns: 5.62225476rem 1.65312808rem 19.15073048rem 5.4607271rem 2.37352442rem 10.85849344rem 39.51514192rem 10.85849344rem 4.50750636rem;
      grid-template-rows: minmax(12.87252632rem, max-content) minmax(1.97462047rem, max-content) minmax(26.528254rem, max-content) minmax(3.83874909rem, max-content) minmax(2.13352945rem, max-content) minmax(1.29364199rem, max-content) minmax(1.95897151rem, max-content) minmax(3.71370105rem, max-content) minmax(1.90855371rem, max-content);
  }

  #c85MiQ8j8pyAFfoz {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }
}

@media (min-width: 768.05px) and (max-width: 1024px) {
  #Gcip3IHrXqoSSerB {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #R4bFHk3K9MhsUuXQ {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #uB9gdUBrmjPAQVE6 {
      grid-area: 4 / 3 / 5 / 4;
      position: relative;
  }

  #xYbBrx6mafzrIqCT {
      font-size: calc(2.62903367em - var(--ffsd));
  }

  #AnOXjoZUpH4TNBbq {
      --first-font-size: 2.62903367em;
      --last-font-size: var(--first-font-size);
      transform: translateX(calc(var(--first-font-size) * 0.02));
      margin-top: calc(var(--first-font-size) * 0.04);
      margin-bottom: calc(var(--last-font-size) * 0.04);
  }

  #FX2sUpNG3cn48QTY {
      min-width: 28.54748118rem;
  }

  #BL7fbA4BBx5IpnqI {
      grid-area: 6 / 2 / 7 / 5;
      position: relative;
  }

  #okdJh55tKLEwvioS {
      grid-area: 2 / 6 / 8 / 7;
      position: relative;
  }

  #Hj2vCWH2yaYdooEL {
      grid-area: 2 / 2 / 9 / 8;
      position: relative;
  }

  #hNM83Z741TiR9kHy {
      grid-area: 3 / 3 / 4 / 6;
      position: relative;
  }

  #gj4YJ4yvRxKWtsAH {
      grid-area: 5 / 5 / 6 / 7;
      position: relative;
  }

  #gdFbF9eUNpIBUzs1 {
      grid-area: 7 / 4 / 8 / 9;
      position: relative;
  }

  #aa2J8LgSNASnS01p {
      grid-template-columns: 0 1.62960034rem 3.10884135rem 8.02863446rem 18.54622249rem 1.62803783rem 0.00156251rem 0;
      grid-template-rows: 0 minmax(2.57868613rem, max-content) minmax(49.84863032rem, max-content) minmax(0.95167889rem, max-content) minmax(0.29282577rem, max-content) minmax(1.45157349rem, max-content) minmax(0.44029501rem, max-content) minmax(0.65885798rem, max-content);
  }

  #mDREwzGjCETykcMJ {
      grid-area: 3 / 8 / 9 / 9;
      position: relative;
  }

  #yH1jqxz2dwWbkSRs {
      grid-template-columns: 2.8077745rem 5.59080714rem 17.31703877rem 5.59080714rem 3.68386429rem 32.06680918rem 0 32.94289898rem;
      grid-template-rows: 0 0 minmax(2.90260036rem, max-content) minmax(21.72073144rem, max-content) minmax(2.74923952rem, max-content) minmax(14.85161113rem, max-content) minmax(13.99836513rem, max-content) 0;
  }

  #EHJe4a9TjZnF5oTb {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #ZvAIGWL199mtdQty {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hd9DCGsDJbnCUTMH {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #p2klDFBhgs2IIzu0 {
      font-size: calc(4.00191801em - var(--ffsd));
  }

  #L6b9uLo7BkyIwbjg {
      font-size: calc(2.73303807em - var(--ffsd));
  }

  #AB5tuhlSHL4A61dS {
      --first-font-size: 4.00191801em;
      --last-font-size: 2.73303807em;
      margin-top: calc(var(--first-font-size) * -0.18);
      margin-bottom: calc(var(--last-font-size) * -0.18);
  }

  #NDPGsVcVomdqV1BM {
      min-width: 88.80431861rem;
  }

  #r90enHsYajhqod6n {
      grid-area: 2 / 2 / 4 / 5;
      position: relative;
  }

  #EBbqjM05jht5l4qT {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #Zh5lAIkgkw3mQD23 {
      font-size: calc(2.45061493em - var(--ffsd));
  }

  #KA1ruxmY3fDQ0CHz {
      --first-font-size: 2.45061493em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #ULKpIAXmD5Yo6ZUm {
      min-width: 21.90382594rem;
  }

  #AkuMzJ6ee2hQPfpP {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #oRCzniDlPNnYzH32 {
      grid-template-columns: 0 0.93583152rem 21.85499782rem 0;
      grid-template-rows: 0 minmax(1.69560038rem, max-content) minmax(2.87737335rem, max-content) minmax(1.83694702rem, max-content);
  }

  #EUGlla4aWTH7F2vY {
      grid-area: 3 / 3 / 5 / 4;
      position: relative;
  }

  #M26r7EuNKRfQjP1f {
      grid-template-columns: 5.62225476rem 32.98233057rem 22.79082934rem 32.98233057rem 5.62225476rem;
      grid-template-rows: minmax(11.03806406rem, max-content) minmax(32.45081909rem, max-content) minmax(1.69560038rem, max-content) minmax(4.71432037rem, max-content) minmax(6.32374368rem, max-content);
  }

  #qxi1dJYpuEgQ3GAP {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #FFamOyY9L43hFdCg {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hgmCugqHt6bM7JSf {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #sbid0hVA5U1i60dK {
      font-size: calc(3.12344802em - var(--ffsd));
  }

  #td6EGz77roQlxnv3 {
      --first-font-size: 3.12344802em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #WemUxubcgv2ggcRw {
      min-width: 50.41379163rem;
  }

  #NwkRNePE1Q9SWrKB {
      grid-area: 2 / 2 / 3 / 6;
      position: relative;
  }

  #Ty23ULE0tB2Nvpea {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #hkg6wjagLVyf04N9 {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #vqV4OOlDqnBupciK {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #ic2Hde6op0tE3pOV {
      --first-font-size: 2.24499268em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.24);
      margin-bottom: calc(var(--last-font-size) * -0.24);
  }

  #nYolEmHEUkq6c9dk {
      min-width: 53.71773162rem;
  }

  #ZRGfJC8CVYOn4ICt {
      grid-area: 4 / 3 / 5 / 7;
      position: relative;
  }

  #Pg1H9KHcUrYWirzx {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #u97ggHtTl5CjCFr2 {
      font-size: calc(2.40915081em - var(--ffsd));
  }

  #NhjFutAzLTBEK3N4 {
      --first-font-size: 2.40915081em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #MUvZvHoZnbpQy2Fg {
      min-width: 21.53401486rem;
  }

  #KxUBSgbSii92WswK {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #ijhRrpO0ZVZxzsH2 {
      grid-template-columns: 0 1.16722813rem 21.48518673rem 1.16722813rem;
      grid-template-rows: 0 minmax(1.86581736rem, max-content) minmax(2.88566618rem, max-content) minmax(1.94779105rem, max-content);
  }

  #JE09gLAD0lOkMTkB {
      grid-area: 6 / 4 / 7 / 5;
      position: relative;
  }

  #OvvMkFI95PY5lI31 {
      grid-template-columns: 5.62225476rem 0 0.6803525rem 23.81964299rem 25.86496801rem 3.30393999rem 40.70884175rem;
      grid-template-rows: minmax(12.70186915rem, max-content) minmax(10.06832064rem, max-content) minmax(2.20763838rem, max-content) minmax(10.12298917rem, max-content) minmax(5.21125733rem, max-content) minmax(6.69927459rem, max-content) minmax(9.21119832rem, max-content);
  }

  #Cy1PaoWfviQxRs5a {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #RmijFNceVqO7Fs3J {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #y1Vye7h8aQaaCSRZ {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #EsHH1pIVIoFxMfcb {
      font-size: calc(2.84680844em - var(--ffsd));
  }

  #jzrAYhXhr34yZVBt {
      --first-font-size: 2.84680844em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #mg6YZjNYcDGsDEM9 {
      min-width: 50.56721761rem;
  }

  #QRqrShqvd6dgCaht {
      grid-area: 2 / 2 / 3 / 4;
      position: relative;
  }

  #JIuf7v0lKOXoVVAx {
      font-size: calc(1.89786755em - var(--ffsd));
  }

  #RIKncFyr9pIUSUie {
      --first-font-size: 1.89786755em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #u9MNcVEjAOZ3rGlU {
      min-width: 50.56721761rem;
  }

  #jXpmqkj2JmUatY0n {
      grid-area: 4 / 3 / 5 / 5;
      position: relative;
  }

  #Iikie6NHQzNDHWuB {
      grid-template-columns: 0 0 50.51838948rem 0;
      grid-template-rows: 0 minmax(3.35923395rem, max-content) minmax(1.10534723rem, max-content) minmax(4.8870221rem, max-content);
  }

  #dsGaowxU9EVzl1tT {
      grid-area: 3 / 2 / 4 / 18;
      position: relative;
  }

  #MIRJ2qV9jGAzI4nY {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #HbBuYw0oCiveIm75 {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #gNgIpczgkfSqPrT7 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #WscXJmmrtcwvsJRP {
      min-width: 18.74092467rem;
  }

  #c13F6904MVKNpyVT {
      grid-area: 5 / 3 / 8 / 12;
      position: relative;
  }

  #U85i265wNQbw8T6A {
      grid-area: 6 / 14 / 7 / 19;
      position: relative;
  }

  #fGXtQn2TxF0u8sQK {
      grid-area: 9 / 4 / 10 / 23;
      position: relative;
  }

  #iBI7gsSYLx6PsCWJ {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #TQqu6u0aLQxKLqIb {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #LjHLSRvoqG4r58Lk {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #QhGfiNC4tKlmqZXA {
      min-width: 13.1487778rem;
  }

  #qtZ1Kp35L1YFOMrC {
      grid-area: 11 / 5 / 14 / 11;
      position: relative;
  }

  #onNVpINpFa5zgEgR {
      grid-area: 12 / 15 / 13 / 20;
      position: relative;
  }

  #WOFSN6jl6Ycg0BXK {
      grid-area: 15 / 6 / 16 / 24;
      position: relative;
  }

  #WwdPYj5hi5OJEaQS {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #CMjC5N8UaaUdalAx {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #m4k18ITxxsL9QFGM {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #L89XGpgiQRagWZxL {
      min-width: 29.57924156rem;
  }

  #sfuRSbOq2STMCRnA {
      grid-area: 17 / 7 / 20 / 13;
      position: relative;
  }

  #uWlILl6qsIML3Abq {
      grid-area: 18 / 16 / 19 / 21;
      position: relative;
  }

  #GxLZcKsgC2Hilfjd {
      grid-area: 21 / 8 / 22 / 25;
      position: relative;
  }

  #aQWCknqjztYHpTod {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #XXC7EZjp69bNCAIa {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #vpnVdpcR5ZSWo8T3 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #mUHVS577UYfY5gke {
      min-width: 12.93323858rem;
  }

  #gRtoAZXOZ73ARV3Y {
      grid-area: 23 / 9 / 26 / 10;
      position: relative;
  }

  #SvMKEAw6mQYt3EGi {
      grid-area: 24 / 17 / 25 / 22;
      position: relative;
  }

  #VptxBkH6YTytsvBQ {
      grid-area: 2 / 26 / 27 / 27;
      position: relative;
  }

  #rF78NrtcKVQUrqZt {
      grid-template-columns: 3.9691309rem 1.65312386rem 0 0 0 0 0 0 12.88441045rem 0.21553922rem 5.59214688rem 10.83831689rem 17.56701623rem 0 0 0 1.76783596rem 0 0 0 0 1.54449348rem 0 0 3.56702802rem 34.77870336rem 5.62225476rem;
      grid-template-rows: minmax(5.62225476rem, max-content) minmax(6.20255199rem, max-content) minmax(9.35160328rem, max-content) minmax(5.0984772rem, max-content) minmax(0.68549779rem, max-content) minmax(1.76783596rem, max-content) minmax(1.41196786rem, max-content) minmax(1.24450952rem, max-content) minmax(0.26842511rem, max-content) minmax(1.31771596rem, max-content) minmax(0.93210419rem, max-content) minmax(1.76783596rem, max-content) minmax(1.16536146rem, max-content) minmax(1.22388171rem, max-content) minmax(0.26842511rem, max-content) minmax(1.46412884rem, max-content) minmax(1.06698088rem, max-content) minmax(1.76783596rem, max-content) minmax(1.03048477rem, max-content) minmax(1.31771596rem, max-content) minmax(0.26842511rem, max-content) minmax(1.49097184rem, max-content) minmax(0.77842787rem, max-content) minmax(1.76783596rem, max-content) minmax(1.31903778rem, max-content) 0 minmax(5.62225476rem, max-content);
  }

  #IGQgWz9buyfmxwOX {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #RBLku5bRpfbZzoL5 {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #moYcaMc9ZCjaQ5Kk {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #l77XCv5ipqp7OSGG {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #tldXqR85ukzB2Xpc {
      font-size: calc(2.06930454em - var(--ffsd));
  }

  #v8dAWh4by7M5pxbI {
      font-size: calc(2.06930454em - var(--ffsd));
  }

  #c7XZiZxPxw03OdfC {
      --first-font-size: 2.06930454em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #mAkg4ABrRdFOVI2J {
      min-width: 18.99568691rem;
  }

  #Pwn7cfpx3FxXo8Mo {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #Z1C31VCWpC0VLV2V {
      grid-template-columns: 0 0.80527086rem 18.94685878rem 0.87847731rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(23.97657321rem, max-content) minmax(4.86802466rem, max-content);
  }

  #u8PbdJDPhGxEhVYX {
      grid-area: 3 / 2 / 9 / 3;
      position: relative;
  }

  #OjAxnq7DlESlsM2B {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #MaNyMNNQPmAYqeNO {
      font-size: calc(2.07392489em - var(--ffsd));
  }

  #ksBwM714QSw4PcGn {
      font-size: calc(2.07392489em - var(--ffsd));
  }

  #KI6UGKNb7ekfusHH {
      --first-font-size: 2.07392489em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #pwOsh6myIKro0Zxz {
      min-width: 18.13231956rem;
  }

  #oVRfPyZBKj86KKEy {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #a2VFO8DotspSemB7 {
      grid-template-columns: 0 1.10293386rem 18.08349143rem 0.94511022rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(23.67254475rem, max-content) minmax(5.17205312rem, max-content);
  }

  #QhxDFEMAfBE4jrLj {
      grid-area: 4 / 4 / 10 / 5;
      position: relative;
  }

  #i5SQlFa08mWt8OU7 {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #nHe50ftLL79P3xgb {
      font-size: calc(2.1473851em - var(--ffsd));
  }

  #BAHZRzIaByTsgLKw {
      font-size: calc(0.78090044em - var(--ffsd));
  }

  #YCRd8V2uExBvjkWO {
      --first-font-size: 2.1473851em;
      --last-font-size: 0.78090044em;
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #HDEyyYz0SekFT46Y {
      min-width: 11.80527375rem;
  }

  #CavGOY9hjCOkcDp6 {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #v9Bn2VIBikYtngpe {
      grid-template-columns: 0 0 11.75644562rem 0;
      grid-template-rows: 0 minmax(4.33626135rem, max-content) minmax(3.74182372rem, max-content) minmax(6.87446899rem, max-content);
  }

  #oK5ft2mais3eEpdb {
      grid-area: 2 / 11 / 5 / 12;
      position: relative;
  }

  #kI5ApGItmf85yhck {
      grid-area: 6 / 6 / 11 / 9;
      position: relative;
  }

  #cnFEflgiysujhtx6 {
      font-size: calc(2.23152334em - var(--ffsd));
  }

  #RiegmrffEAK6Ib0v {
      font-size: calc(1.78990963em - var(--ffsd));
  }

  #J4wEpTeqRP5K1HcN {
      font-size: calc(1.78990963em - var(--ffsd));
  }

  #r3V5CUIdBRTJnFOm {
      --first-font-size: 2.23152334em;
      --last-font-size: 1.78990963em;
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #mPGRDzyWfG82Z5P8 {
      min-width: 18.09849523rem;
  }

  #UXbjzZC3YybOVETT {
      grid-area: 8 / 7 / 13 / 8;
      position: relative;
  }

  #Nd6DmbZ6aBQIRg0a {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #rgkJVqu4suGcTb7S {
      font-size: calc(2.23152269em - var(--ffsd));
  }

  #evcD5UXFTfvykY49 {
      font-size: calc(1.8740776em - var(--ffsd));
  }

  #YsqZH9U9kIXmnuMA {
      --first-font-size: 2.23152269em;
      --last-font-size: 1.8740776em;
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #YlGLAzV42ZwrciJk {
      min-width: 18.09849523rem;
  }

  #Wpq2384czQxH5ar0 {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #wKILIpJB3Xh5vn2P {
      grid-template-columns: 0 0.8228348rem 18.0496671rem 0.8228348rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(24.93320827rem, max-content) minmax(3.9113896rem, max-content);
  }

  #jPrUqChMZEzXArN7 {
      grid-area: 7 / 10 / 12 / 13;
      position: relative;
  }

  #Sa7Jx5tZzWs5J5nY {
      grid-template-columns: 5.79217985rem 20.63060695rem 2.34260615rem 20.13153551rem 2.59398003rem 1.19238944rem 18.0496671rem 1.35290664rem 1.97657394rem 7.93889108rem 11.75644562rem 0 6.2422177rem;
      grid-template-rows: 0 minmax(14.95255405rem, max-content) 0 0 0 0 minmax(6.80314091rem, max-content) minmax(28.84459787rem, max-content) 0 0 0 minmax(0.42608356rem, max-content) minmax(5.1961712rem, max-content);
  }

  #zwKgah8dqbXVOUpH {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #SggXgrATxLg27eBg {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #T3PpJIYdrbgl5nVF {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #Cmsk5UCmTRIxuVBf {
      grid-area: 3 / 4 / 5 / 7;
      position: relative;
  }

  #X8l0aTR5IlPmwv6M {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hOE8WAqz5b9ndbqd {
      grid-template-columns: 0 44.39589498rem;
      grid-template-rows: 0 minmax(33.97268383rem, max-content);
  }

  #EONmJlhFuBfu0xiM {
      grid-area: 4 / 2 / 6 / 6;
      position: relative;
  }

  #G5XcDjTvJfhcL1KU {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #Wt9TTYELXBum9yxE {
      font-size: calc(2.45912884em - var(--ffsd));
  }

  #sJEemP9xMe2ftdkJ {
      --first-font-size: 2.45912884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #q8ggHWJBcXqflX2C {
      min-width: 21.97970504rem;
  }

  #qSHJbRetCVKoRsdO {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #Rf1saeE2U1lQU1vt {
      grid-template-columns: 0 0 21.93087692rem 0.01242755rem;
      grid-template-rows: 0 minmax(1.5711768rem, max-content) minmax(3.02208346rem, max-content) minmax(1.57829413rem, max-content);
  }

  #KtETJnG6VhxbQeN3 {
      grid-area: 7 / 3 / 8 / 5;
      position: relative;
  }

  #W12Mp8BOWiruKw9c {
      font-size: calc(2.92825769em - var(--ffsd));
  }

  #xNnCswL01UvjYUMd {
      --first-font-size: 2.92825769em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #c2ZiFqm2RsHSfKuj {
      min-width: 24.93843055rem;
  }

  #ykzECYCWLJWIMnLZ {
      grid-area: 2 / 2 / 3 / 4;
      position: relative;
  }

  #RsdNPazz5xf8WxLz {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #V8kGV1eLT5Tr5VZq {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #xDjh9qFYQ29yPKXW {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #I4jckjq6QSn0pIBp {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #kzdHaTA9PJVl4OaB {
      --first-font-size: 1.95215959em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #XexK9M4YS6jTwRSB {
      min-width: 24.93843055rem;
  }

  #OK5e9K9ewpI2JMKx {
      grid-area: 4 / 3 / 5 / 5;
      position: relative;
  }

  #w1wHkC1Yu5MxO1LO {
      grid-template-columns: 0 0 24.88960242rem 0;
      grid-template-rows: 0 minmax(3.45534407rem, max-content) minmax(2.55526197rem, max-content) minmax(45.6564202rem, max-content);
  }

  #OK1vTNfjGM1ov4vV {
      grid-area: 2 / 8 / 9 / 9;
      position: relative;
  }

  #wqYjr9PTCOkGmL0C {
      grid-template-columns: 5.60410502rem 19.0615714rem 3.90304349rem 18.04026098rem 3.3910191rem 15.21492213rem 2.70986191rem 24.88960242rem 7.18561354rem;
      grid-template-rows: minmax(10.09455456rem, max-content) minmax(1.43804374rem, max-content) minmax(4.48094564rem, max-content) minmax(18.76945765rem, max-content) minmax(15.20322618rem, max-content) minmax(4.81737686rem, max-content) minmax(6.17155438rem, max-content) minmax(0.7864218rem, max-content) minmax(10.09455456rem, max-content);
  }

  #T2NMkGKZOUkwAcmX {
      min-height: calc(17.56051855 * var(--1vh, 1vh));
  }

  #vAFmLVjpViOO8pEh {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #NdSP5GhekaOasljF {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #N6VpBGKRcBI8WMxl {
      font-size: calc(2.92825769em - var(--ffsd));
  }

  #ULaD2Y01Sk5mMOak {
      --first-font-size: 2.92825769em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #nRfnhX0YzOaQ0Xdw {
      min-width: 26.31341379rem;
  }

  #eRPPNza9h5HcdgF7 {
      grid-area: 2 / 2 / 3 / 9;
      position: relative;
  }

  #oHXHqNq8CxsueFnV {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #bylecg2blPm2OwBL {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #koN7beDlYDRe7jfg {
      min-width: 26.31341379rem;
  }

  #owTAQ0z6seFDL7zt {
      grid-area: 4 / 3 / 5 / 10;
      position: relative;
  }

  #n6ALYRGoNIPBU9e2 {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #E5A0QPuBdhE3VROO {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #vUxUbys62KfUqI6i {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #ZV7uUR4i3lj84q73 {
      min-width: 26.31341379rem;
  }

  #MRdQu4FBvWGPGryX {
      grid-area: 6 / 4 / 7 / 11;
      position: relative;
  }

  #aTIpy788F0YNw6gv {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #FBw4YWZt1Y5OIuqw {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #sXAaBpS0zx4H2vOJ {
      min-width: 26.31341379rem;
  }

  #BGFMS2RR1T9II4YV {
      grid-area: 8 / 5 / 9 / 12;
      position: relative;
  }

  #BYPYCHDU7OlSjV2c {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #OMWWFAeGDtRPV7Sb {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #mO4wzJEPpkxOftS4 {
      min-width: 26.31341379rem;
  }

  #bxYt3OgDKC4xflmY {
      grid-area: 10 / 6 / 11 / 13;
      position: relative;
  }

  #XkHSwDE7oqwMyWL7 {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #pFHmlClCkB6kdaez {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #pD0kzGq1kLx54a2S {
      min-width: 26.31341379rem;
  }

  #hpeyZ92ab31eREpU {
      grid-area: 12 / 7 / 13 / 14;
      position: relative;
  }

  #iLAnHYrLq0AZ1fbe {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #fVzlRlIKSIjHpp2J {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #jiNcs7LojO8ChMNY {
      min-width: 26.31341379rem;
  }

  #TDcUqR484nocyt77 {
      grid-area: 14 / 8 / 15 / 15;
      position: relative;
  }

  #ejASdF8seWdXb62i {
      grid-template-columns: 0 0 0 0 0 0 0 26.26458566rem 0 0 0 0 0 0;
      grid-template-rows: 0 minmax(3.45534407rem, max-content) minmax(3.18272207rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(3.80673499rem, max-content) minmax(1.98516502rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(1.75695461rem, max-content) minmax(1.98516502rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(1.75695461rem, max-content);
  }

  #WsXKJi0tUQy6M0Yj {
      grid-area: 3 / 2 / 4 / 5;
      position: relative;
  }

  #o103wLUjzjdJ0vbj {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #bLn45zJKclaSXxXB {
      font-size: calc(2.14738653em - var(--ffsd));
  }

  #gWwroNFESTLBcPfT {
      --first-font-size: 2.14738653em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #YWW3MOpmOgfb841P {
      min-width: 19.1995586rem;
  }

  #JCjXP2hfxHABIryt {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #cptnjYzeKMbd7tD2 {
      grid-template-columns: 0 0 19.15073048rem 0;
      grid-template-rows: 0 minmax(1.37047484rem, max-content) minmax(2.64519327rem, max-content) minmax(1.37047484rem, max-content);
  }

  #dNV7QOHJmBMIR8do {
      grid-area: 5 / 3 / 8 / 4;
      position: relative;
  }

  #ODW2Zr4KWDqq5gBJ {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #K0gdNuRVqJ4C0sMP {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #J3wv3cJg8jTi4Gn6 {
      grid-template-columns: 0 18.61661692rem 23.99889497rem 18.61661692rem;
      grid-template-rows: 0 0 minmax(30.47749495rem, max-content) minmax(3.99765807rem, max-content);
  }

  #AW5WKBsna8IRStre {
      grid-area: 2 / 6 / 6 / 9;
      position: relative;
  }

  #ExVlBTD6zGtQxqgL {
      grid-area: 2 / 2 / 7 / 7;
      position: relative;
  }

  #UMhTMNGScmWqTQH2 {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #FIC9eZslsweKSBg0 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #uWXcr0A1k6OazN9g {
      min-width: 23.91494851rem;
  }

  #bAa0QtZVlTHParfB {
      grid-area: 4 / 3 / 5 / 4;
      position: relative;
  }

  #MU7RIMCyCaEqHZOq {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #Cwnw3RXYBtarBT3W {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #DmTeAPJIN9S3eeqO {
      grid-template-columns: 0 0.8965513rem 1.59410699rem 0.84795565rem;
      grid-template-rows: 0 minmax(0.87225348rem, max-content) minmax(1.59410699rem, max-content) minmax(0.87225348rem, max-content);
  }

  #zbHp22QpXxxTF8oc {
      grid-template-columns: 0 4.47419318rem 23.86612039rem 2.19283912rem 3.33861395rem 5.64337528rem;
      grid-template-rows: 0 minmax(0.9399522rem, max-content) minmax(0.61513347rem, max-content) minmax(2.108347rem, max-content) minmax(0.61513347rem, max-content) minmax(1.39410641rem, max-content);
  }

  #ysr65jjvWIT1gbxc {
      grid-area: 7 / 7 / 9 / 8;
      position: relative;
  }

  #eIdYn4hrBVqteSux {
      grid-template-columns: 5.62225476rem 1.65312808rem 19.15073048rem 5.4607271rem 2.37352442rem 10.85849344rem 39.51514192rem 10.85849344rem 4.50750636rem;
      grid-template-rows: minmax(12.87252632rem, max-content) minmax(1.97462047rem, max-content) minmax(26.528254rem, max-content) minmax(3.83874909rem, max-content) minmax(2.13352945rem, max-content) minmax(1.29364199rem, max-content) minmax(1.95897151rem, max-content) minmax(3.71370105rem, max-content) minmax(1.90855371rem, max-content);
  }

  #c85MiQ8j8pyAFfoz {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }
}

@media (min-width: 1024.05px) {
  #Gcip3IHrXqoSSerB {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #R4bFHk3K9MhsUuXQ {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #uB9gdUBrmjPAQVE6 {
      grid-area: 4 / 3 / 5 / 4;
      position: relative;
  }

  #xYbBrx6mafzrIqCT {
      font-size: calc(2.62903367em - var(--ffsd));
  }

  #AnOXjoZUpH4TNBbq {
      --first-font-size: 2.62903367em;
      --last-font-size: var(--first-font-size);
      transform: translateX(calc(var(--first-font-size) * 0.02));
      margin-top: calc(var(--first-font-size) * 0.04);
      margin-bottom: calc(var(--last-font-size) * 0.04);
  }

  #FX2sUpNG3cn48QTY {
      min-width: 28.53525627rem;
  }

  #BL7fbA4BBx5IpnqI {
      grid-area: 6 / 2 / 7 / 5;
      position: relative;
  }

  #okdJh55tKLEwvioS {
      grid-area: 2 / 6 / 8 / 7;
      position: relative;
  }

  #Hj2vCWH2yaYdooEL {
      grid-area: 2 / 2 / 9 / 8;
      position: relative;
  }

  #hNM83Z741TiR9kHy {
      grid-area: 3 / 3 / 4 / 6;
      position: relative;
  }

  #gj4YJ4yvRxKWtsAH {
      grid-area: 5 / 5 / 6 / 7;
      position: relative;
  }

  #gdFbF9eUNpIBUzs1 {
      grid-area: 7 / 4 / 8 / 9;
      position: relative;
  }

  #aa2J8LgSNASnS01p {
      grid-template-columns: 0 1.62960034rem 3.10884135rem 8.02863446rem 18.54622249rem 1.62803783rem 0.00156251rem 0;
      grid-template-rows: 0 minmax(2.57868613rem, max-content) minmax(49.84863032rem, max-content) minmax(0.95167889rem, max-content) minmax(0.29282577rem, max-content) minmax(1.45157349rem, max-content) minmax(0.44029501rem, max-content) minmax(0.65885798rem, max-content);
  }

  #mDREwzGjCETykcMJ {
      grid-area: 3 / 8 / 9 / 9;
      position: relative;
  }

  #yH1jqxz2dwWbkSRs {
      grid-template-columns: 2.8077745rem 5.59080714rem 17.31703877rem 5.59080714rem 3.68386429rem 32.06680918rem 0 32.94289898rem;
      grid-template-rows: 0 0 minmax(2.90260036rem, max-content) minmax(21.72073144rem, max-content) minmax(2.74923952rem, max-content) minmax(14.85161113rem, max-content) minmax(13.99836513rem, max-content) 0;
  }

  #EHJe4a9TjZnF5oTb {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #ZvAIGWL199mtdQty {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hd9DCGsDJbnCUTMH {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #p2klDFBhgs2IIzu0 {
      font-size: calc(4.00191801em - var(--ffsd));
  }

  #L6b9uLo7BkyIwbjg {
      font-size: calc(2.73303807em - var(--ffsd));
  }

  #AB5tuhlSHL4A61dS {
      --first-font-size: 4.00191801em;
      --last-font-size: 2.73303807em;
      margin-top: calc(var(--first-font-size) * -0.18);
      margin-bottom: calc(var(--last-font-size) * -0.18);
  }

  #NDPGsVcVomdqV1BM {
      min-width: 88.7920937rem;
  }

  #r90enHsYajhqod6n {
      grid-area: 2 / 2 / 4 / 5;
      position: relative;
  }

  #EBbqjM05jht5l4qT {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #Zh5lAIkgkw3mQD23 {
      font-size: calc(2.45061493em - var(--ffsd));
  }

  #KA1ruxmY3fDQ0CHz {
      --first-font-size: 2.45061493em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #ULKpIAXmD5Yo6ZUm {
      min-width: 21.89160104rem;
  }

  #AkuMzJ6ee2hQPfpP {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #oRCzniDlPNnYzH32 {
      grid-template-columns: 0 0.93583152rem 21.85499782rem 0;
      grid-template-rows: 0 minmax(1.69560038rem, max-content) minmax(2.87737335rem, max-content) minmax(1.83694702rem, max-content);
  }

  #EUGlla4aWTH7F2vY {
      grid-area: 3 / 3 / 5 / 4;
      position: relative;
  }

  #M26r7EuNKRfQjP1f {
      grid-template-columns: 5.62225476rem 32.98233057rem 22.79082934rem 32.98233057rem 5.62225476rem;
      grid-template-rows: minmax(11.03806406rem, max-content) minmax(32.45081909rem, max-content) minmax(1.69560038rem, max-content) minmax(4.71432037rem, max-content) minmax(6.32374368rem, max-content);
  }

  #qxi1dJYpuEgQ3GAP {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #FFamOyY9L43hFdCg {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hgmCugqHt6bM7JSf {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #sbid0hVA5U1i60dK {
      font-size: calc(3.12344802em - var(--ffsd));
  }

  #td6EGz77roQlxnv3 {
      --first-font-size: 3.12344802em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #WemUxubcgv2ggcRw {
      min-width: 50.40156672rem;
  }

  #NwkRNePE1Q9SWrKB {
      grid-area: 2 / 2 / 3 / 6;
      position: relative;
  }

  #Ty23ULE0tB2Nvpea {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #hkg6wjagLVyf04N9 {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #vqV4OOlDqnBupciK {
      font-size: calc(2.24499268em - var(--ffsd));
  }

  #ic2Hde6op0tE3pOV {
      --first-font-size: 2.24499268em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.24);
      margin-bottom: calc(var(--last-font-size) * -0.24);
  }

  #nYolEmHEUkq6c9dk {
      min-width: 53.70550671rem;
  }

  #ZRGfJC8CVYOn4ICt {
      grid-area: 4 / 3 / 5 / 7;
      position: relative;
  }

  #Pg1H9KHcUrYWirzx {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #u97ggHtTl5CjCFr2 {
      font-size: calc(2.40915081em - var(--ffsd));
  }

  #NhjFutAzLTBEK3N4 {
      --first-font-size: 2.40915081em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #MUvZvHoZnbpQy2Fg {
      min-width: 21.52178995rem;
  }

  #KxUBSgbSii92WswK {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #ijhRrpO0ZVZxzsH2 {
      grid-template-columns: 0 1.16722813rem 21.48518673rem 1.16722813rem;
      grid-template-rows: 0 minmax(1.86581736rem, max-content) minmax(2.88566618rem, max-content) minmax(1.94779105rem, max-content);
  }

  #JE09gLAD0lOkMTkB {
      grid-area: 6 / 4 / 7 / 5;
      position: relative;
  }

  #OvvMkFI95PY5lI31 {
      grid-template-columns: 5.62225476rem 0 0.6803525rem 23.81964299rem 25.86496801rem 3.30393999rem 40.70884175rem;
      grid-template-rows: minmax(12.70186915rem, max-content) minmax(10.06832064rem, max-content) minmax(2.20763838rem, max-content) minmax(10.12298917rem, max-content) minmax(5.21125733rem, max-content) minmax(6.69927459rem, max-content) minmax(9.21119832rem, max-content);
  }

  #Cy1PaoWfviQxRs5a {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #RmijFNceVqO7Fs3J {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #y1Vye7h8aQaaCSRZ {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #EsHH1pIVIoFxMfcb {
      font-size: calc(2.84680844em - var(--ffsd));
  }

  #jzrAYhXhr34yZVBt {
      --first-font-size: 2.84680844em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #mg6YZjNYcDGsDEM9 {
      min-width: 50.5549927rem;
  }

  #QRqrShqvd6dgCaht {
      grid-area: 2 / 2 / 3 / 4;
      position: relative;
  }

  #JIuf7v0lKOXoVVAx {
      font-size: calc(1.89786755em - var(--ffsd));
  }

  #RIKncFyr9pIUSUie {
      --first-font-size: 1.89786755em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #u9MNcVEjAOZ3rGlU {
      min-width: 50.5549927rem;
  }

  #jXpmqkj2JmUatY0n {
      grid-area: 4 / 3 / 5 / 5;
      position: relative;
  }

  #Iikie6NHQzNDHWuB {
      grid-template-columns: 0 0 50.51838948rem 0;
      grid-template-rows: 0 minmax(3.35923395rem, max-content) minmax(1.10534723rem, max-content) minmax(4.8870221rem, max-content);
  }

  #dsGaowxU9EVzl1tT {
      grid-area: 3 / 2 / 4 / 18;
      position: relative;
  }

  #MIRJ2qV9jGAzI4nY {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #HbBuYw0oCiveIm75 {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #gNgIpczgkfSqPrT7 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #WscXJmmrtcwvsJRP {
      min-width: 18.72869977rem;
  }

  #c13F6904MVKNpyVT {
      grid-area: 5 / 3 / 8 / 12;
      position: relative;
  }

  #U85i265wNQbw8T6A {
      grid-area: 6 / 14 / 7 / 19;
      position: relative;
  }

  #fGXtQn2TxF0u8sQK {
      grid-area: 9 / 4 / 10 / 23;
      position: relative;
  }

  #iBI7gsSYLx6PsCWJ {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #TQqu6u0aLQxKLqIb {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #LjHLSRvoqG4r58Lk {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #QhGfiNC4tKlmqZXA {
      min-width: 13.13655289rem;
  }

  #qtZ1Kp35L1YFOMrC {
      grid-area: 11 / 5 / 14 / 11;
      position: relative;
  }

  #onNVpINpFa5zgEgR {
      grid-area: 12 / 15 / 13 / 20;
      position: relative;
  }

  #WOFSN6jl6Ycg0BXK {
      grid-area: 15 / 6 / 16 / 24;
      position: relative;
  }

  #WwdPYj5hi5OJEaQS {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #CMjC5N8UaaUdalAx {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #m4k18ITxxsL9QFGM {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #L89XGpgiQRagWZxL {
      min-width: 29.56701665rem;
  }

  #sfuRSbOq2STMCRnA {
      grid-area: 17 / 7 / 20 / 13;
      position: relative;
  }

  #uWlILl6qsIML3Abq {
      grid-area: 18 / 16 / 19 / 21;
      position: relative;
  }

  #GxLZcKsgC2Hilfjd {
      grid-area: 21 / 8 / 22 / 25;
      position: relative;
  }

  #aQWCknqjztYHpTod {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #XXC7EZjp69bNCAIa {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #vpnVdpcR5ZSWo8T3 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #mUHVS577UYfY5gke {
      min-width: 12.92101367rem;
  }

  #gRtoAZXOZ73ARV3Y {
      grid-area: 23 / 9 / 26 / 10;
      position: relative;
  }

  #SvMKEAw6mQYt3EGi {
      grid-area: 24 / 17 / 25 / 22;
      position: relative;
  }

  #VptxBkH6YTytsvBQ {
      grid-area: 2 / 26 / 27 / 27;
      position: relative;
  }

  #rF78NrtcKVQUrqZt {
      grid-template-columns: 3.9691309rem 1.65312386rem 0 0 0 0 0 0 12.88441045rem 0.21553922rem 5.59214688rem 10.83831689rem 17.56701623rem 0 0 0 1.76783596rem 0 0 0 0 1.54449348rem 0 0 3.56702802rem 34.77870336rem 5.62225476rem;
      grid-template-rows: minmax(5.62225476rem, max-content) minmax(6.20255199rem, max-content) minmax(9.35160328rem, max-content) minmax(5.0984772rem, max-content) minmax(0.68549779rem, max-content) minmax(1.76783596rem, max-content) minmax(1.41196786rem, max-content) minmax(1.24450952rem, max-content) minmax(0.26842511rem, max-content) minmax(1.31771596rem, max-content) minmax(0.93210419rem, max-content) minmax(1.76783596rem, max-content) minmax(1.16536146rem, max-content) minmax(1.22388171rem, max-content) minmax(0.26842511rem, max-content) minmax(1.46412884rem, max-content) minmax(1.06698088rem, max-content) minmax(1.76783596rem, max-content) minmax(1.03048477rem, max-content) minmax(1.31771596rem, max-content) minmax(0.26842511rem, max-content) minmax(1.49097184rem, max-content) minmax(0.77842787rem, max-content) minmax(1.76783596rem, max-content) minmax(1.31903778rem, max-content) 0 minmax(5.62225476rem, max-content);
  }

  #IGQgWz9buyfmxwOX {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #RBLku5bRpfbZzoL5 {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #moYcaMc9ZCjaQ5Kk {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #l77XCv5ipqp7OSGG {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #tldXqR85ukzB2Xpc {
      font-size: calc(2.06930454em - var(--ffsd));
  }

  #v8dAWh4by7M5pxbI {
      font-size: calc(2.06930454em - var(--ffsd));
  }

  #c7XZiZxPxw03OdfC {
      --first-font-size: 2.06930454em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #mAkg4ABrRdFOVI2J {
      min-width: 18.98346201rem;
  }

  #Pwn7cfpx3FxXo8Mo {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #Z1C31VCWpC0VLV2V {
      grid-template-columns: 0 0.80527086rem 18.94685878rem 0.87847731rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(23.97657321rem, max-content) minmax(4.86802466rem, max-content);
  }

  #u8PbdJDPhGxEhVYX {
      grid-area: 3 / 2 / 9 / 3;
      position: relative;
  }

  #OjAxnq7DlESlsM2B {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #MaNyMNNQPmAYqeNO {
      font-size: calc(2.07392489em - var(--ffsd));
  }

  #ksBwM714QSw4PcGn {
      font-size: calc(2.07392489em - var(--ffsd));
  }

  #KI6UGKNb7ekfusHH {
      --first-font-size: 2.07392489em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #pwOsh6myIKro0Zxz {
      min-width: 18.12009465rem;
  }

  #oVRfPyZBKj86KKEy {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #a2VFO8DotspSemB7 {
      grid-template-columns: 0 1.10293386rem 18.08349143rem 0.94511022rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(23.67254475rem, max-content) minmax(5.17205312rem, max-content);
  }

  #QhxDFEMAfBE4jrLj {
      grid-area: 4 / 4 / 10 / 5;
      position: relative;
  }

  #i5SQlFa08mWt8OU7 {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #nHe50ftLL79P3xgb {
      font-size: calc(2.1473851em - var(--ffsd));
  }

  #BAHZRzIaByTsgLKw {
      font-size: calc(0.78090044em - var(--ffsd));
  }

  #YCRd8V2uExBvjkWO {
      --first-font-size: 2.1473851em;
      --last-font-size: 0.78090044em;
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #HDEyyYz0SekFT46Y {
      min-width: 11.79304884rem;
  }

  #CavGOY9hjCOkcDp6 {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #v9Bn2VIBikYtngpe {
      grid-template-columns: 0 0 11.75644562rem 0;
      grid-template-rows: 0 minmax(4.33626135rem, max-content) minmax(3.74182372rem, max-content) minmax(6.87446899rem, max-content);
  }

  #oK5ft2mais3eEpdb {
      grid-area: 2 / 11 / 5 / 12;
      position: relative;
  }

  #kI5ApGItmf85yhck {
      grid-area: 6 / 6 / 11 / 9;
      position: relative;
  }

  #cnFEflgiysujhtx6 {
      font-size: calc(2.23152334em - var(--ffsd));
  }

  #RiegmrffEAK6Ib0v {
      font-size: calc(1.78990963em - var(--ffsd));
  }

  #J4wEpTeqRP5K1HcN {
      font-size: calc(1.78990963em - var(--ffsd));
  }

  #r3V5CUIdBRTJnFOm {
      --first-font-size: 2.23152334em;
      --last-font-size: 1.78990963em;
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #mPGRDzyWfG82Z5P8 {
      min-width: 18.08627032rem;
  }

  #UXbjzZC3YybOVETT {
      grid-area: 8 / 7 / 13 / 8;
      position: relative;
  }

  #Nd6DmbZ6aBQIRg0a {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #rgkJVqu4suGcTb7S {
      font-size: calc(2.23152269em - var(--ffsd));
  }

  #evcD5UXFTfvykY49 {
      font-size: calc(1.8740776em - var(--ffsd));
  }

  #YsqZH9U9kIXmnuMA {
      --first-font-size: 2.23152269em;
      --last-font-size: 1.8740776em;
      margin-top: calc(var(--first-font-size) * -0.15);
      margin-bottom: calc(var(--last-font-size) * -0.15);
  }

  #YlGLAzV42ZwrciJk {
      min-width: 18.08627032rem;
  }

  #Wpq2384czQxH5ar0 {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #wKILIpJB3Xh5vn2P {
      grid-template-columns: 0 0.8228348rem 18.0496671rem 0.8228348rem;
      grid-template-rows: 0 minmax(6.80314091rem, max-content) minmax(24.93320827rem, max-content) minmax(3.9113896rem, max-content);
  }

  #jPrUqChMZEzXArN7 {
      grid-area: 7 / 10 / 12 / 13;
      position: relative;
  }

  #Sa7Jx5tZzWs5J5nY {
      grid-template-columns: 5.79217985rem 20.63060695rem 2.34260615rem 20.13153551rem 2.59398003rem 1.19238944rem 18.0496671rem 1.35290664rem 1.97657394rem 7.93889108rem 11.75644562rem 0 6.2422177rem;
      grid-template-rows: 0 minmax(14.95255405rem, max-content) 0 0 0 0 minmax(6.80314091rem, max-content) minmax(28.84459787rem, max-content) 0 0 0 minmax(0.42608356rem, max-content) minmax(5.1961712rem, max-content);
  }

  #zwKgah8dqbXVOUpH {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }

  #SggXgrATxLg27eBg {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #T3PpJIYdrbgl5nVF {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #Cmsk5UCmTRIxuVBf {
      grid-area: 3 / 4 / 5 / 7;
      position: relative;
  }

  #X8l0aTR5IlPmwv6M {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #hOE8WAqz5b9ndbqd {
      grid-template-columns: 0 44.39589498rem;
      grid-template-rows: 0 minmax(33.97268383rem, max-content);
  }

  #EONmJlhFuBfu0xiM {
      grid-area: 4 / 2 / 6 / 6;
      position: relative;
  }

  #G5XcDjTvJfhcL1KU {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #Wt9TTYELXBum9yxE {
      font-size: calc(2.45912884em - var(--ffsd));
  }

  #sJEemP9xMe2ftdkJ {
      --first-font-size: 2.45912884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #q8ggHWJBcXqflX2C {
      min-width: 21.96748014rem;
  }

  #qSHJbRetCVKoRsdO {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #Rf1saeE2U1lQU1vt {
      grid-template-columns: 0 0 21.93087692rem 0.01242755rem;
      grid-template-rows: 0 minmax(1.5711768rem, max-content) minmax(3.02208346rem, max-content) minmax(1.57829413rem, max-content);
  }

  #KtETJnG6VhxbQeN3 {
      grid-area: 7 / 3 / 8 / 5;
      position: relative;
  }

  #W12Mp8BOWiruKw9c {
      font-size: calc(2.92825769em - var(--ffsd));
  }

  #xNnCswL01UvjYUMd {
      --first-font-size: 2.92825769em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #c2ZiFqm2RsHSfKuj {
      min-width: 24.92620564rem;
  }

  #ykzECYCWLJWIMnLZ {
      grid-area: 2 / 2 / 3 / 4;
      position: relative;
  }

  #RsdNPazz5xf8WxLz {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #V8kGV1eLT5Tr5VZq {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #xDjh9qFYQ29yPKXW {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #I4jckjq6QSn0pIBp {
      font-size: calc(1.95215959em - var(--ffsd));
  }

  #kzdHaTA9PJVl4OaB {
      --first-font-size: 1.95215959em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #XexK9M4YS6jTwRSB {
      min-width: 24.92620564rem;
  }

  #OK5e9K9ewpI2JMKx {
      grid-area: 4 / 3 / 5 / 5;
      position: relative;
  }

  #w1wHkC1Yu5MxO1LO {
      grid-template-columns: 0 0 24.88960242rem 0;
      grid-template-rows: 0 minmax(3.45534407rem, max-content) minmax(2.55526197rem, max-content) minmax(45.6564202rem, max-content);
  }

  #OK1vTNfjGM1ov4vV {
      grid-area: 2 / 8 / 9 / 9;
      position: relative;
  }

  #wqYjr9PTCOkGmL0C {
      grid-template-columns: 5.60410502rem 19.0615714rem 3.90304349rem 18.04026098rem 3.3910191rem 15.21492213rem 2.70986191rem 24.88960242rem 7.18561354rem;
      grid-template-rows: minmax(10.09455456rem, max-content) minmax(1.43804374rem, max-content) minmax(4.48094564rem, max-content) minmax(18.76945765rem, max-content) minmax(15.20322618rem, max-content) minmax(4.81737686rem, max-content) minmax(6.17155438rem, max-content) minmax(0.7864218rem, max-content) minmax(10.09455456rem, max-content);
  }

  #T2NMkGKZOUkwAcmX {
      min-height: calc(17.56051855 * var(--1vh, 1vh));
  }

  #vAFmLVjpViOO8pEh {
      grid-area: 2 / 2 / 3 / 3;
      position: relative;
  }

  #NdSP5GhekaOasljF {
      grid-template-columns: 0 100%;
      grid-template-rows: 0 100%;
  }

  #N6VpBGKRcBI8WMxl {
      font-size: calc(2.92825769em - var(--ffsd));
  }

  #ULaD2Y01Sk5mMOak {
      --first-font-size: 2.92825769em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #nRfnhX0YzOaQ0Xdw {
      min-width: 26.30118888rem;
  }

  #eRPPNza9h5HcdgF7 {
      grid-area: 2 / 2 / 3 / 9;
      position: relative;
  }

  #oHXHqNq8CxsueFnV {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #bylecg2blPm2OwBL {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #koN7beDlYDRe7jfg {
      min-width: 26.30118888rem;
  }

  #owTAQ0z6seFDL7zt {
      grid-area: 4 / 3 / 5 / 10;
      position: relative;
  }

  #n6ALYRGoNIPBU9e2 {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #E5A0QPuBdhE3VROO {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #vUxUbys62KfUqI6i {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #ZV7uUR4i3lj84q73 {
      min-width: 26.30118888rem;
  }

  #MRdQu4FBvWGPGryX {
      grid-area: 6 / 4 / 7 / 11;
      position: relative;
  }

  #aTIpy788F0YNw6gv {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #FBw4YWZt1Y5OIuqw {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #sXAaBpS0zx4H2vOJ {
      min-width: 26.30118888rem;
  }

  #BGFMS2RR1T9II4YV {
      grid-area: 8 / 5 / 9 / 12;
      position: relative;
  }

  #BYPYCHDU7OlSjV2c {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #OMWWFAeGDtRPV7Sb {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #mO4wzJEPpkxOftS4 {
      min-width: 26.30118888rem;
  }

  #bxYt3OgDKC4xflmY {
      grid-area: 10 / 6 / 11 / 13;
      position: relative;
  }

  #XkHSwDE7oqwMyWL7 {
      font-size: calc(1.95217179em - var(--ffsd));
  }

  #pFHmlClCkB6kdaez {
      --first-font-size: 1.95217179em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.09);
      margin-bottom: calc(var(--last-font-size) * 0.09);
  }

  #pD0kzGq1kLx54a2S {
      min-width: 26.30118888rem;
  }

  #hpeyZ92ab31eREpU {
      grid-area: 12 / 7 / 13 / 14;
      position: relative;
  }

  #iLAnHYrLq0AZ1fbe {
      font-size: calc(1.46412884em - var(--ffsd));
  }

  #fVzlRlIKSIjHpp2J {
      --first-font-size: 1.46412884em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #jiNcs7LojO8ChMNY {
      min-width: 26.30118888rem;
  }

  #TDcUqR484nocyt77 {
      grid-area: 14 / 8 / 15 / 15;
      position: relative;
  }

  #ejASdF8seWdXb62i {
      grid-template-columns: 0 0 0 0 0 0 0 26.26458566rem 0 0 0 0 0 0;
      grid-template-rows: 0 minmax(3.45534407rem, max-content) minmax(3.18272207rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(3.80673499rem, max-content) minmax(1.98516502rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(1.75695461rem, max-content) minmax(1.98516502rem, max-content) minmax(2.34536837rem, max-content) minmax(0.52103617rem, max-content) minmax(1.75695461rem, max-content);
  }

  #WsXKJi0tUQy6M0Yj {
      grid-area: 3 / 2 / 4 / 5;
      position: relative;
  }

  #o103wLUjzjdJ0vbj {
      grid-area: 2 / 2 / 5 / 4;
      position: relative;
  }

  #bLn45zJKclaSXxXB {
      font-size: calc(2.14738653em - var(--ffsd));
  }

  #gWwroNFESTLBcPfT {
      --first-font-size: 2.14738653em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * -0.1);
      margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #YWW3MOpmOgfb841P {
      min-width: 19.1873337rem;
  }

  #JCjXP2hfxHABIryt {
      grid-area: 3 / 3 / 4 / 5;
      position: relative;
  }

  #cptnjYzeKMbd7tD2 {
      grid-template-columns: 0 0 19.15073048rem 0;
      grid-template-rows: 0 minmax(1.37047484rem, max-content) minmax(2.64519327rem, max-content) minmax(1.37047484rem, max-content);
  }

  #dNV7QOHJmBMIR8do {
      grid-area: 5 / 3 / 8 / 4;
      position: relative;
  }

  #ODW2Zr4KWDqq5gBJ {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #K0gdNuRVqJ4C0sMP {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #J3wv3cJg8jTi4Gn6 {
      grid-template-columns: 0 18.61661692rem 23.99889497rem 18.61661692rem;
      grid-template-rows: 0 0 minmax(30.47749495rem, max-content) minmax(3.99765807rem, max-content);
  }

  #AW5WKBsna8IRStre {
      grid-area: 2 / 6 / 6 / 9;
      position: relative;
  }

  #ExVlBTD6zGtQxqgL {
      grid-area: 2 / 2 / 7 / 7;
      position: relative;
  }

  #UMhTMNGScmWqTQH2 {
      font-size: calc(1.75696193em - var(--ffsd));
  }

  #FIC9eZslsweKSBg0 {
      --first-font-size: 1.75696193em;
      --last-font-size: var(--first-font-size);
      margin-top: calc(var(--first-font-size) * 0.1);
      margin-bottom: calc(var(--last-font-size) * 0.1);
  }

  #uWXcr0A1k6OazN9g {
      min-width: 23.90272361rem;
  }

  #bAa0QtZVlTHParfB {
      grid-area: 4 / 3 / 5 / 4;
      position: relative;
  }

  #MU7RIMCyCaEqHZOq {
      grid-area: 2 / 2 / 5 / 5;
      position: relative;
  }

  #Cwnw3RXYBtarBT3W {
      grid-area: 3 / 3 / 4 / 4;
      position: relative;
  }

  #DmTeAPJIN9S3eeqO {
      grid-template-columns: 0 0.8965513rem 1.59410699rem 0.84795565rem;
      grid-template-rows: 0 minmax(0.87225348rem, max-content) minmax(1.59410699rem, max-content) minmax(0.87225348rem, max-content);
  }

  #zbHp22QpXxxTF8oc {
      grid-template-columns: 0 4.47419318rem 23.86612039rem 2.19283912rem 3.33861395rem 5.64337528rem;
      grid-template-rows: 0 minmax(0.9399522rem, max-content) minmax(0.61513347rem, max-content) minmax(2.108347rem, max-content) minmax(0.61513347rem, max-content) minmax(1.39410641rem, max-content);
  }

  #ysr65jjvWIT1gbxc {
      grid-area: 7 / 7 / 9 / 8;
      position: relative;
  }

  #eIdYn4hrBVqteSux {
      grid-template-columns: 5.62225476rem 1.65312808rem 19.15073048rem 5.4607271rem 2.37352442rem 10.85849344rem 39.51514192rem 10.85849344rem 4.50750636rem;
      grid-template-rows: minmax(12.87252632rem, max-content) minmax(1.97462047rem, max-content) minmax(26.528254rem, max-content) minmax(3.83874909rem, max-content) minmax(2.13352945rem, max-content) minmax(1.29364199rem, max-content) minmax(1.95897151rem, max-content) minmax(3.71370105rem, max-content) minmax(1.90855371rem, max-content);
  }

  #c85MiQ8j8pyAFfoz {
      min-height: calc(13.73991357 * var(--1vh, 1vh));
  }
}

// @keyframes baseline-RIGHT-6dd4dd69-3f49-4fc1-ac11-96c7a32b61f6 {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes baseline-LEFT-b310b178-0e26-426e-90e8-d38fc1a380cd {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes baseline-LEFT-910ba1a2-4ed3-4a0b-9bec-ad3097f42ff2 {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes breathe-LEFT-a82bd025-73a6-4cda-8add-acba4c87c80a {
//   0% {
//       transform: scale(0.8);
//       animation-timing-function: linear;
//   }

//   50% {
//       transform: scale(0.97);
//       animation-timing-function: linear;
//   }

//   100% {
//       transform: scale(1);
//   }
// }

// @keyframes wipe-LEFT-2814b2fa-0d35-463b-941b-62d5426e004f {
//   0% {
//       clip-path: inset(-60% 100% -60% -10%);
//       animation-timing-function: cubic-bezier(0.31, 0.92, 0.6, 1.01);
//   }

//   100% {
//       clip-path: inset(-60% -10% -60% -10%);
//   }
// }

// @keyframes wipe-RIGHT-e54408c3-5768-4bcd-91a1-0d236c41a4e4 {
//   0% {
//       clip-path: inset(-60% 100% -60% -10%);
//       animation-timing-function: cubic-bezier(0.31, 0.92, 0.6, 1.01);
//   }

//   100% {
//       clip-path: inset(-60% -10% -60% -10%);
//   }
// }

// @keyframes wipe-LEFT-7ae6bf93-afe1-488d-90f4-8613cf629692 {
//   0% {
//       clip-path: inset(-60% 100% -60% -10%);
//       animation-timing-function: cubic-bezier(0.31, 0.92, 0.6, 1.01);
//   }

//   100% {
//       clip-path: inset(-60% -10% -60% -10%);
//   }
// }

// @keyframes baseline-RIGHT-508549ff-23f6-4cf6-8b36-2c403c232e24 {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes pulse {
//   0% {
//       background-color: rgba(226, 226, 226, 0.05);
//   }

//   50% {
//       background-color: rgba(226, 226, 226, 0.1);
//   }

//   100% {
//       background-color: rgba(226, 226, 226, 0.05);
//   }
// }

// @keyframes wipe-LEFT-9d55a982-9a4c-4b0e-aee6-4563daebf8f5 {
//   0% {
//       clip-path: inset(-60% 100% -60% -10%);
//       animation-timing-function: cubic-bezier(0.31, 0.92, 0.6, 1.01);
//   }

//   100% {
//       clip-path: inset(-60% -10% -60% -10%);
//   }
// }

// @keyframes baseline-LEFT-58aa9047-4c2e-4fdb-9433-87e4f9d4d111 {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes linear_fade {
//   0% {
//       opacity: 0.0;
//   }

//   100% {
//       opacity: 1.0;
//   }
// }

// @keyframes baseline-LEFT-6aa5f1de-ec89-4a5f-b61a-9cd056f45358 {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes wipe-LEFT-ac1d594d-c2df-4894-aa94-08eaf7dedde4 {
//   0% {
//       clip-path: inset(-60% 100% -60% -10%);
//       animation-timing-function: cubic-bezier(0.31, 0.92, 0.6, 1.01);
//   }

//   100% {
//       clip-path: inset(-60% -10% -60% -10%);
//   }
// }

// @keyframes breathe-RIGHT-955f41a9-f0ee-42e0-9f9e-c0ab54f90d7f {
//   0% {
//       transform: scale(0.8);
//       animation-timing-function: linear;
//   }

//   50% {
//       transform: scale(0.97);
//       animation-timing-function: linear;
//   }

//   100% {
//       transform: scale(1);
//   }
// }

// @keyframes wipe-RIGHT-8b84232a-896a-4257-a2f2-6bf525997384 {
//   0% {
//       clip-path: inset(-60% 100% -60% -10%);
//       animation-timing-function: cubic-bezier(0.31, 0.92, 0.6, 1.01);
//   }

//   100% {
//       clip-path: inset(-60% -10% -60% -10%);
//   }
// }

// @keyframes baseline-LEFT-3f55d95d-974f-4dcc-96ae-97d42f86beb6 {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes baseline-RIGHT-6bfde62d-b4cc-4ee9-869a-81d9567a81d1 {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes baseline-RIGHT-2e4050f9-3efd-4326-a2a7-79185a712fb8 {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes wipe-RIGHT-9282025a-7cf0-4682-871a-4f92cd11f0ca {
//   0% {
//       clip-path: inset(-60% 100% -60% -10%);
//       animation-timing-function: cubic-bezier(0.31, 0.92, 0.6, 1.01);
//   }

//   100% {
//       clip-path: inset(-60% -10% -60% -10%);
//   }
// }

// @keyframes baseline-LEFT-87e72ba0-8012-4cac-9b54-d6927be107ac {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes breathe-LEFT-5cbd11b9-4860-4337-82ba-f5b630d0afb6 {
//   0% {
//       transform: scale(0.8);
//       animation-timing-function: linear;
//   }

//   50% {
//       transform: scale(0.97);
//       animation-timing-function: linear;
//   }

//   100% {
//       transform: scale(1);
//   }
// }

// @keyframes baseline-LEFT-da2cb3a1-f881-4cf3-b922-a2dbda41c2ac {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes baseline-LEFT-010502a0-e8f8-4923-a286-eceae063bcfa {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes wipe-RIGHT-aafd878b-17f6-4901-8360-2f797c8b3f67 {
//   0% {
//       clip-path: inset(-60% 100% -60% -10%);
//       animation-timing-function: cubic-bezier(0.31, 0.92, 0.6, 1.01);
//   }

//   100% {
//       clip-path: inset(-60% -10% -60% -10%);
//   }
// }

// @keyframes baseline-LEFT-091a4b84-fbb4-4870-a7df-a8d935cd001f {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes baseline-RIGHT-2f5e432d-cfa7-4b6f-b507-7dee9485fd0b {
//   0% {
//       transform: translate(0%, 100%);
//       animation-timing-function: cubic-bezier(0.15, 1.03, 0.34, 0.99);
//   }

//   100% {
//       transform: translate(0px, 0px);
//   }
// }

// @keyframes wipe-RIGHT-270e8eec-e906-4bc7-b48f-1595b32bb1d4 {
//   0% {
//       clip-path: inset(-60% 100% -60% -10%);
//       animation-timing-function: cubic-bezier(0.31, 0.92, 0.6, 1.01);
//   }

//   100% {
//       clip-path: inset(-60% -10% -60% -10%);
//   }
// }

// @keyframes wipe-RIGHT-f03bf044-10f7-4f13-9ff2-337b7bd7f942 {
//   0% {
//       clip-path: inset(-60% 100% -60% -10%);
//       animation-timing-function: cubic-bezier(0.31, 0.92, 0.6, 1.01);
//   }

//   100% {
//       clip-path: inset(-60% -10% -60% -10%);
//   }
// }