.form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  &__group:not(:last-child) {
    margin: 0 3rem 2rem 0;
  }

  @include respond(phone) {
    &__group:not(:last-child) {
      margin: 0 0 0 0;
    }
  }

  &__input {
    font-size: $font-size-0;
    padding: 2rem 3rem 2rem 1rem;
    border: none;
    border-bottom: 3px solid transparent;
    font-family: inherit;
    color: $white-1;
    width: 20rem;
    display: block;
    background-color: transparent;
    border-bottom: 3px solid $white-1;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $white-1;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $white-1;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $white-1;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $white-1;
    }
    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($black-1, 0.1);
      border-bottom: 3px solid $white-1;
    }
    &:focus:invalid {
      border-bottom: 3px solid $red-1;
    }
    &-message {
      width: 45rem;
      @include respond(big-desktop) {
        width: 70rem;
      }

      @include respond(tab-port) {
        width: 15rem;
        margin-bottom: 0;
      }
      @include respond(phone) {
        width: 20rem;
        margin-bottom: 10rem;
      }
    }

    @include respond(phone) {
      width: 10rem;
    }
  }

  &__label {
    font-size: $font-size-0;
    font-weight: bold;
    margin: 1rem 0 0 1rem;
    display: block;
    transition: all 0.3s;
    text-align: start;
    color: $white-1;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-5rem);
  }

  @include respond(tab-port) {
    margin-top: 35rem;
    align-items: center;
    flex-direction: column;
  }
  @include respond(phone) {
    margin-top: 25rem;
    align-items: center;
    flex-direction: column;
  }
}
